<template>
  <div class="wrapper">
    <div class="about-title">
      <h2 class="about-text">CONTACT</h2>
    </div>
  </div>
  <div class="all-wrapper">
    <h3 class="about-contact-title">お問い合わせ</h3>
    <div class="contact-wrapper">
      <div class="contact">
        <div class="explain">
          <p>採用・サービスに関するご相談などお気軽にお問い合せください。 担当者より返信させていただきます。</p>
        </div>
      </div>
      <form action="/ContactConfirmation">
        <div class="flow-step">
          <div class="flow-step-list">
            <p class="step-status" v-for="step in steps" :key="step.id">{{ step.status }}</p>
          </div>
        </div>
        <ul class="for">
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">ご相談内容</p>
              <p class="required">必須</p>
            </label>
            <div class="consultation" v-for="consultation in consultations" :key="consultation.id">
              <label>
                <input
                  class="consultation-contents"
                  type="radio"
                  required
                  :value="consultation.value"
                  v-model="formData.content"
                />
                {{ consultation.about }}
              </label>
            </div>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">お名前</p>
              <p class="required">必須</p>
            </label>
            <input
              type="text"
              class="inputText"
              name="entry.1828008031"
              placeholder="お名前をご入力してください。"
              required
              v-model="formData.name"
            />
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">ふりがな</p>
              <p class="required">必須</p>
            </label>
            <input
              type="text"
              class="inputText"
              name="entry.840914999"
              placeholder="お名前(ふりがな)をご入力してください。"
              required
              v-model="formData.ruby"
            />
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">メールアドレス</p>
              <p class="required">必須</p>
            </label>
            <input
              type="email"
              class="inputText"
              name="entry.2007747466"
              placeholder="メールアドレスをご入力してください。"
              required
              v-model="formData.email"
            />
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">電話番号</p>
              <p class="required">必須</p>
            </label>
            <input
              type="tel"
              class="inputText"
              name="entry.455978347"
              placeholder="電話番号をご入力してください。"
              required
              v-model="formData.tel"
            />
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">お問い合せ内容</p>
              <p class="required">必須</p>
            </label>
            <textarea id="msg" v-model="formData.messages" maxlength="1000" name="entry.992045842" required></textarea>
          </li>
          <li class="type-form">
            <div class="privacy">
              <p class="privacy-text">
                <a target="_blank" class="privacy_policy_link" href="/privacy-policy">プライバシーポリシー</a>
                を確認する
              </p>
            </div>
          </li>
          <li class="type-form">
            <input type="checkbox" v-model="formData.privacy" class="checkbox" id="privacy" required />
            <label class="item" for="item">
              <p class="privacy_policy">プライバシーポリシーに同意する</p>
            </label>
          </li>
          <div class="confirmation">
            <input
              v-on:click="submitForm"
              id="button"
              class="confirmation_button"
              name="button"
              type="submit"
              value="入力内容の確認"
            />
          </div>
        </ul>
      </form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        privacy: false,
      },
      steps: [
        {
          status: '入力',
        },
        {
          status: '確認',
        },
        {
          status: '完了',
        },
      ],
      consultations: [
        {
          about: 'お問い合せ',
          name: 'entry.1515114129',
          value: 'お問い合せ',
        },
        {
          about: '採用について',
          name: 'entry.1515114129',
          value: '採用について',
        },
        {
          about: 'その他',
          name: 'entry.1515114129',
          value: 'その他',
        },
      ],
    }
  },
  created() {
    const savedData = localStorage.getItem('formData')
    if (savedData) {
      this.formData = JSON.parse(savedData)
    }
  },
  methods: {
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return emailPattern.test(email)
    },
    submitForm() {
      if (this.formData.privacy) {
        if (this.validateEmail(this.formData.email)) {
          this.$router.push('/ContactConfirmation')
        } else {
          console.log('有効なメールアドレスを入力してください。')
        }
      } else {
        console.log('プライバシーポリシーに同意してください。')
      }
      localStorage.setItem('formData', JSON.stringify(this.$data.formData))
    },
  },
}
</script>

<style lang="scss" scoped>
.contact {
  padding: 0 40px;
}

.explain {
  text-align: center;
}

.flow-step {
  margin-top: 32px;
  font-size: 20px;
  text-align: -webkit-center;
  .flow-step-list {
    border-bottom: 2px solid #ff7300;
    display: flex;
    justify-content: center;
    width: 300px;
    .step-status {
      color: #b7b7b7;
      font-weight: bold;
      margin: 0 25px 10.5px 0;
    }
    .step-status:nth-child(1) {
      color: #ff6700;
    }
    .step-status:nth-child(1)::after {
      color: #b7b7b7;
    }
    .step-status:nth-child(3) {
      margin-right: 0;
    }
    .step-status::after {
      content: '>';
      margin-left: 17px;
    }
    .step-status:nth-child(3)::after {
      content: '';
    }
  }
}

.for {
  padding: 0;
  .type-form {
    list-style: none;
    margin-top: 55px;
    .item {
      display: flex;
      .information {
        font-size: 18px;
        font-weight: bold;
        margin-right: 20px;
      }
      .required {
        border: 1.5px solid #ff7300;
        border-radius: 4px;
        color: #ff7300;
        font-size: 14px;
        font-weight: 600;
        padding: 3px 9px;
      }
    }
    .consultation {
      margin-bottom: 8px;
      .consultation-contents {
        margin-right: 15px;
      }
    }
  }
  .type-form {
    .inputText {
      font-size: 14px;
      width: 100%;
      border: 1px solid #707070;
      height: 33px;
    }
    .inputText::placeholder {
      padding: 0 0 0 9px;
    }
    #msg {
      height: 256px;
      width: 100%;
      resize: none;
    }
  }
  .type-form:nth-child(2) {
    margin-top: 37px;
  }
  .type-form:nth-child(3),
  .type-form:nth-child(5) {
    margin-top: 17px;
  }
  .type-form:nth-child(4) {
    margin-top: 35px;
  }
  .type-form:nth-child(6) {
    margin-top: 41px;
  }
  .type-form:nth-child(7) {
    .privacy {
      .privacy-text {
        margin-bottom: 0px;
        font-size: 17px;
        .privacy_policy_link {
          color: #000;
          text-decoration: none;
        }
        .privacy_policy_link:hover {
          color: #ff6700;
        }
      }
    }
  }
  .type-form:nth-child(8) {
    display: flex;
    margin-top: 30px;
    .checkbox {
      width: 25px;
    }
    .item {
      .privacy_policy {
        font-size: 17px;
        font-weight: bold;
        margin-left: 14px;
      }
    }
  }
  .confirmation {
    margin-top: 103px;
    text-align: -webkit-center;
    .confirmation_button {
      background-color: #ff7300;
      border: 1px solid #ff7300;
      border-radius: 6px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      padding: 10px 36px;
    }
    .confirmation_button:hover {
      background-color: #fff;
      color: #ff7300;
    }
  }
}

@media screen and (max-width: 795px) {
  .contact {
    padding: 0;
  }
  .explain {
    font-size: 14px;
  }
  .flow-step {
    margin-top: 49px;
  }
  .for {
    .type-form {
      display: block;
      .item {
        align-items: flex-end;
        font-size: 14px;
        .information {
          font-size: 15px;
          margin-bottom: 10px;
        }
        .required {
          font-size: 11px;
          height: 16px;
          margin-bottom: 10px;
          padding: 2px 9px 0;
        }
      }
      .consultation {
        font-size: 11px;
        .consultation-contents {
          margin-right: 12px;
          position: relative;
          top: 2px;
        }
      }
    }
    .type-form {
      .inputText {
        font-size: 11px;
        height: 27px;
      }
      #msg {
        height: 212px;
      }
    }
    .type-form:nth-child(2) {
      margin-top: 31px;
    }
    .type-form:nth-child(3),
    .type-form:nth-child(5) {
      margin-top: 21px;
    }
    .type-form:nth-child(4) {
      margin-top: 21px;
    }
    .type-form:nth-child(6) {
      margin-top: 31px;
    }
    .type-form:nth-child(7) {
      .privacy {
        .privacy-text {
          font-size: 14px;
        }
      }
    }
    .type-form:nth-child(8) {
      margin-top: 5px;
      .checkbox {
        width: 20px;
      }
      .item {
        .privacy_policy {
          font-size: 13px;
        }
      }
    }
    .confirmation {
      margin-top: 51px;
    }
  }
}

.wrapper {
  font-family: 'Noto Sans Japanese';
  background-color: #f4f4f4;
  width: 100%;
}

.about-title {
  background-image: url('/src/assets/img/headline.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  margin-top: 100px;
  .about-text {
    color: #fd9602;
    position: absolute;
    font-size: 70px;
    top: 30%;
    left: 10%;
  }
}

.about-contact-title {
  font-size: 25px;
  margin: 5px 0 250px;
  font-weight: normal;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    margin: 7px;
    width: 70px;
    position: initial;
    border-top: solid 5px #ff5900;
  }
}
.all-wrapper {
  font-style: normal;
  font-weight: 300;
  padding: 0 10% 250px;
  background: url('../assets/img/about-bg.jpg');
}

.link {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.contact-wrapper {
  background-color: #fff;
  padding: 77px 13%;
  width: unset;
}

@media screen and (min-width: 795px) {
  .page-header {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .page-header + form {
    margin-top: 80px;
  }
  .icon {
    background-repeat: no-repeat;
    height: 100px;
    margin-right: 30px;
    width: 100px;
  }
}

@media screen and (max-width: 794px) {
  .wrapper {
    padding-bottom: 0;
  }

  .about-title {
    height: 75px;
    margin-top: 70px;
    .about-text {
      font-size: 25px;
    }
  }

  .about-contact-title {
    font-size: 13px;
    margin: 2px 10%;
    font-size: 13px;
    text-align: left;
    &::before {
      content: '';
      width: 40px;
      margin: 2px;
      position: initial;
      border-top: solid 3px #ff5900;
    }
  }
  .all-wrapper {
    padding: 0;
    background: none;
  }
  .contact-wrapper {
    background-color: #fff;
    padding: 29px 45px;
    width: unset;
  }
  .page-header {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }
  .page-header + form {
    margin-top: 40px;
  }
  .icon {
    background-repeat: no-repeat;
    height: 35px;
    margin-right: 10px;
    width: 35px;
  }
}
</style>
