<template>
  <main>
    <div class="home">
      <section>
        <div class="p-main-visual">
          <div class="p-main-visual__slide">
            <ul class="p-main-visual__slide-list">
              <li class="p-main-visual__slide-item" :key="idx" v-for="(slide, idx) in slides">
                <transition name="fade">
                  <div class="p-main-visual__slide-switch" v-show="current_slide == idx">
                    <img class="slide-img" v-bind:src="imgSrc(idx)" :key="imgSrc(idx)" />
                  </div>
                </transition>
              </li>
            </ul>
          </div>
          <div class="p-main-visual__headline">
            <h2 class="p-main-visual__ttl">Run up with you</h2>
            <p class="p-main-visual__sub-ttl">成長を加速させる企業へ</p>
          </div>
        </div>
      </section>
      <section class="about-iRup about-wrapper">
        <div class="iRup-info">
          <h1 class="main-title">ABOUT</h1>
          <h2 class="main-sub-title">企業概要</h2>
          <div class="about-contents">
            <div class="intro-contents">
              <div class="iRup-intro">
                <div class="sentenceAndButton">
                  <p class="about-sentences">
                    iRupは、お客様の成長をサポートするために、最先端の技術と独自のサービスを提供しています。企業概要ページでは、代表の思い、また弊社の情報について詳しく説明しています。こちらのリンクから、iRupの魅力や取り組みをご覧ください。
                  </p>
                  <div class="about-button">
                    <a href="/about" class="view-more">View More</a>
                  </div>
                </div>
              </div>
              <div class="iRup-image">
                <img class="about-image" src="../assets/img/top_about.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="service-contents">
        <div class="service">
          <div class="service-wrapper">
            <h1 class="main-title">SERVICE</h1>
            <h2 class="main-sub-title">事業内容</h2>
            <p class="service-intro">
              iRupでは、テックソリューション事業、受託開発事業、教育事業、自社開発事業の4つの柱で、お客様のニーズに応えています。各事業の詳細については、こちらのリンクからご覧いただけます。私たちと共に、革新的なソリューションを創造し、ビジネスを成功に導きましょう。
            </p>
          </div>
          <ul class="kind-of-services">
            <li class="service-images" v-for="service in services" :key="service.id">
              <div class="imageAndTitle">
                <img :src="service.serviceImage" :alt="alt" class="service-image" />
                <div class="service-title">
                  <span class="no">{{ service.no }}</span>
                  <h2 class="kind">{{ service.title }}</h2>
                </div>
              </div>
              <div class="service-button">
                <a :href="service.path" class="view-more">View More</a>
              </div>
            </li>
          </ul>
        </div>
      </section>
      <section class="recruit-service">
        <div class="recruit wrapper">
          <h1 class="main-title">RECRUIT</h1>
          <h2 class="main-sub-title">採用情報</h2>
          <div class="recruit-text-image">
            <div class="text">
              <p class="recruit-text">
                iRupでは、チャレンジ精神溢れる人材を求めています。採用情報ページでは、募集要項、選考プロセス、福利厚生、そして社内の雰囲気などを紹介しています。こちらのリンクから、iRupで一緒に働く魅力を感じていただけることでしょう。あなたの力を活かし、共に成長しましょう。
              </p>
            </div>
            <div class="recruit-contents">
              <div class="recruit-button">
                <a href="#" class="view-more">View More</a>
              </div>
              <img src="../assets/img/top_recruit_01.jpg" class="recruit-image" />
            </div>
          </div>
          <div class="imagesAndButton">
            <div class="recruit-images">
              <img
                v-for="recruite_image in recruite_images"
                :key="recruite_image.id"
                :src="recruite_image.image"
                class="image"
              />
            </div>
            <div class="recruit-button">
              <a href="/recruit" class="view-more">View More</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </main>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {
      current_slide: 0,
      slides: [{ img: 'mv.jpg' }, { img: 'slide02.jpg' }, { img: 'slide03.jpg' }],
      services: [
        {
          no: '01',
          title: ' - 受託開発',
          serviceImage: require('../assets/img/top_service_01.jpg'),
          path: '/business',
        },
        {
          no: '02',
          title: '- テックソリューション',
          serviceImage: require('../assets/img/top_service_02.jpg'),
          path: '/business',
        },
        {
          no: '03',
          title: ' - 教育事業',
          serviceImage: require('../assets/img/top_service_03.jpg'),
          path: '/business',
        },
        {
          no: '04',
          title: ' - 自社開発',
          serviceImage: require('../assets/img/top_service_04.jpg'),
          path: '/business',
        },
      ],
      recruite_images: [
        {
          image: require('../assets/img/top_recruit_02.png'),
        },
        {
          image: require('../assets/img/top_recruit_03.png'),
        },
      ],
    }
  },
  methods: {
    imgSrc(idx) {
      return require('../assets/img/' + this.slides[idx].img)
    },
  },
  mounted() {
    setInterval(() => {
      this.current_slide = this.current_slide < this.slides.length - 1 ? this.current_slide + 1 : 0
    }, 3500)
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/sass/homeview.scss';
.wrapper {
  background-color: #fff;
  padding: 0 8.2% 120px;
}
@media screen and (max-width: 795px) {
  .wrapper {
    padding: 50px 8.2% 120px;
  }
}
</style>
