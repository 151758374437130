<template>
  <div class="employee-post-page-wrap">
    <div class="employee-post-page">
      <nav class="nav">
        <ul class="nav-list">
          <router-link to="/postnews" class="nav-item-btn">ニュース</router-link>
          <router-link to="/controlEvent" class="nav-item-btn">イベント</router-link>
          <router-link to="/postemployee" class="nav-item-btn">社員ページ</router-link>
        </ul>
      </nav>
      <section class="employee-post-form-wrap">
        <div class="employee-post-form">
          <div class="employee-title-field">
            <p class="employee-heading">名前</p>
            <input
              type="text"
              name="name"
              v-model="nameInput"
              class="employee-title-input"
              placeholder="タイトル"
              required
            />
          </div>
          <div class="employee-motto-field">
            <p class="employee-heading">ポジション</p>
            <input type="text" class="employee-motto-input" v-model="positionInput" placeholder="ポジション" />
          </div>
          <div class="employee-motto-field">
            <p class="employee-heading">座右の銘</p>
            <input type="text" class="employee-motto-input" v-model="mottoInput" placeholder="座右の銘" />
          </div>
          <div class="employee-imageURL-field">
            <p class="employee-heading">画像URL</p>
            <input type="text" class="employee-imageURL-input" v-model="fileUrlInput" placeholder="画像ファイルパス" />
          </div>
          <div class="employee-display-toggle-sort">
            <div class="employee-sort-wrapper">
              <p class="employee-sort-text">ソート</p>
              <input class="employee-sort-input" type="number" name="category_id" v-model="sortInput" min="0" />
            </div>
            <div class="employee-display-switch">
              <p class="employee-heading">表示/非表示</p>
              <div class="toggle-btn">
                <label for="switch">
                  <input type="checkbox" name="toggle" id="switch" v-on:click="toggleSwitch" />
                  <div class="toggle-btn-base"></div>
                  <div class="toggle-btn-circle"></div>
                </label>
              </div>
            </div>
          </div>
          <div class="employee-history-field">
            <p class="employee-heading">経歴</p>
            <textarea class="employee-history-input" v-model="historyInput" name="text" placeholder="経歴入力" />
          </div>
          <div class="employee-textarea-field">
            <p class="employee-heading">iRupへの想い</p>
            <textarea class="employee-textarea-input" v-model="textInput" name="text" placeholder="本文入力" />
          </div>
          <a href="#overlay" class="jump-click">
            <button type="button" class="post-btn" v-on:click="openOverlay">投稿する</button>
          </a>
        </div>
      </section>
      <h2 class="past-employee-heading">過去記事</h2>
      <section class="past-employee-wrap">
        <ul class="past-employee-list">
          <li class="past-employee-item" v-for="member in membersList" :key="member.id">
            <p class="past-employee-title">{{ member.name }}</p>
            <div class="display-mark" v-if="member.display == 0">非表示</div>
            <button
              type="button"
              class="edit-btn"
              v-on:click="
                openEditModal(
                  member.id,
                  member.name,
                  member.position,
                  member.motto,
                  member.file_name,
                  member.history,
                  member.content,
                  member.display,
                  member.sort
                )
              "
            >
              編集
            </button>
            <button type="button" class="delete-btn" v-on:click="deleteDisplay(member.id, member.title)">削除</button>
          </li>
        </ul>
      </section>
    </div>
    <div id="overlay" v-show="overlayDisplay">
      <div class="employee-confirm-wrap" v-show="confirmDisplay">
        <div class="employee-confirm">
          <section class="employee-confirm-alert-wrap">
            <p class="employee-confirm-alert">入力内容の確認をしてください。</p>
          </section>
          <section class="employee-confirm-content-wrap">
            <div class="employee-confirm-content">
              <div class="employee-confirm-display" v-if="this.displayValue === 0">非表示</div>
              <div class="employee-confirm-heading">名前</div>
              <p class="employee-confirm-text">{{ nameInput }}</p>
              <div class="employee-confirm-heading">ポジション</div>
              <p class="employee-confirm-position">{{ positionInput }}</p>
              <div class="employee-confirm-heading">座右の銘</div>
              <p class="employee-confirm-motto">{{ mottoInput }}</p>
              <div class="employee-confirm-heading">画像URL</div>
              <p class="employee-confirm-motto">{{ fileUrlInput }}</p>
              <!-- <img class="employee-confirm-attachment" v-show="uploadedFile" :src="uploadedFile" /> -->
              <div class="employee-confirm-heading">ソート</div>
              <p class="employee-confirm-sort">{{ sortInput }}</p>
              <div class="employee-confirm-heading">経歴</div>
              <p class="employee-confirm-textarea">{{ historyInput }}</p>
              <div class="employee-confirm-heading">iRupへの想い</div>
              <p class="employee-confirm-textarea">{{ textInput }}</p>
            </div>
          </section>
          <div class="employee-confirm-btn-wrap">
            <button type="button" class="back-form-button" v-on:click="backForm">戻る</button>
            <a href="#overlay" class="jump-click">
              <button
                class="employee-confilm-post-button"
                v-on:click="
                  submit(nameInput, positionInput, mottoInput, fileUrlInput, historyInput, textInput, sortInput)
                "
              >
                投稿する
              </button>
            </a>
          </div>
        </div>
      </div>
      <div class="post-completion-wrap" v-show="complitedDisplay">
        <div class="post-completion">
          <p class="completion-message">投稿完了しました。</p>
          <button class="close-button" type="button" v-on:click="closeAll">戻る</button>
        </div>
      </div>
      <div class="edit-modal-wrap" v-show="editModalDisplay">
        <div class="edit-modal">
          <section class="edit-modal-content-wrap">
            <div class="edit-modal-content">
              <div class="edit-title-field">
                <p class="edit-heading">名前</p>
                <input type="text" name="name" class="edit-title-input" placeholder="名前" v-model="updateName" />
              </div>
              <div class="edit-title-field">
                <p class="edit-heading">ポジション</p>
                <input
                  type="text"
                  name="motto"
                  class="edit-title-input"
                  placeholder="ポジション"
                  v-model="updatePosition"
                />
              </div>
              <div class="edit-title-field">
                <p class="edit-heading">座右の銘</p>
                <input type="text" name="motto" class="edit-title-input" placeholder="座右の銘" v-model="updateMotto" />
              </div>
              <div class="edit-title-field">
                <p class="edit-heading">画像URL</p>
                <input
                  type="text"
                  class="employee-imageURL-input"
                  v-model="updateFileName"
                  placeholder="画像ファイルパス"
                />
              </div>
              <div class="edit-flex">
                <div class="employee-sort-field">
                  <p class="employee-heading">ソート</p>
                  <input type="number" class="employee-sort-input" v-model="updateSort" placeholder="ソート" min="0" />
                </div>
                <div class="edit-display-switch">
                  <p class="edit-heading">表示/非表示</p>
                  <div class="edit-toggle-btn">
                    <label for="edit-switch">
                      <input
                        type="checkbox"
                        name="toggle"
                        id="edit-switch"
                        v-model="updateChecked"
                        v-on:click="toggleSwitch"
                      />
                      <div class="edit-toggle-btn-base"></div>
                      <div class="edit-toggle-btn-circle"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div class="edit-textarea-field">
                <p class="edit-heading">経歴</p>
                <textarea
                  class="edit-textarea-input"
                  name="history"
                  placeholder="経歴"
                  v-model="updateHistory"
                  required
                />
              </div>
              <div class="edit-textarea-field">
                <p class="edit-heading">iRupへの思い</p>
                <textarea
                  class="edit-textarea-input"
                  name="content"
                  placeholder="iRupへの思い"
                  v-model="updateContent"
                  required
                />
              </div>
            </div>
            <div class="edit-modal-btn-wrap">
              <button type="button" class="edit-modal-back-btn" v-on:click="backEditModal">戻る</button>
              <button type="button" class="edit-modal-btn" v-on:click="editDisplay">編集完了</button>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'postemployeeView',
  data() {
    return {
      overlayDisplay: false,
      confirmDisplay: false,
      complitedDisplay: false,
      membersList: [],
      nameInput: '',
      positionInput: '',
      mottoInput: '',
      fileUrlInput: '',
      sortInput: '',
      confirmToggleDisplay: false,
      historyInput: '',
      displayValue: 0,
      textInput: '',
      targetId: '',
      updateName: '',
      updatePosition: '',
      updateMotto: '',
      updateHistory: '',
      updateContent: '',
      updateFileName: '',
      updateSort: '',
      updateDisplay: '',
      editToggleDisplay: true,
      editModalDisplay: false,
      updateChecked: '',
    }
  },
  methods: {
    openOverlay: function () {
      if (!this.nameInput) {
        alert('名前を入力してください。')
      } else if (!this.positionInput) {
        alert('ポジションを入力してください。')
      } else if (!this.mottoInput) {
        alert('座右の銘を入力してください。')
      } else if (!this.fileUrlInput) {
        alert('画像URLを入力してください。')
      } else if (this.sortInput === '') {
        alert('ソートを入力してください。')
      } else if (!this.historyInput) {
        alert('経歴を入力してください。')
      } else if (!this.textInput) {
        alert('iRupへの想いを入力してください。')
      } else {
        this.overlayDisplay = true
        this.confirmDisplay = true
        if (this.displayValue == 0) {
          this.displayText = '非表示'
        } else {
          this.displayText = '表示'
        }
      }
    },
    toggleSwitch: function () {
      if (this.confirmToggleDisplay == true) {
        this.displayValue = 0
      } else {
        this.displayValue = 1
      }
      if (this.confirmToggleDisplay == true) {
        this.confirmToggleDisplay = false
      } else {
        this.confirmToggleDisplay = true
      }
      if (this.updateDisplay == 0) {
        this.updateDisplay = 0
      } else {
        this.updateDisplay = 1
      }
    },
    backForm: function () {
      this.overlayDisplay = false
      this.confirmDisplay = false
    },
    submit: function (nameInput, positionInput, mottoInput, fileUrlInput, historyInput, textInput, sortInput) {
      this.$axios
        .post('/api/v1/employee/post', {
          name: nameInput,
          position: positionInput,
          motto: mottoInput,
          file_name: fileUrlInput,
          display: this.displayValue,
          history: historyInput,
          content: textInput,
          sort: sortInput,
        })
        .then((res) => {
          this.posts = res.data.posts
          this.complitedDisplay = true
          this.confirmDisplay = false
        })
        .catch((err) => {
          alert('投稿に失敗しました。\n入力内容をご確認下さい。')
          console.log(err)
        })
    },
    closeAll: function () {
      window.location.reload()
      this.overlayDisplay = false
      this.complitedDisplay = false
    },
    openCompleted: function () {
      window.location.reload()
      this.complitedDisplay = true
      this.confirmDisplay = false
    },
    deleteDisplay: function (id, title) {
      this.targetId = id
      const result = window.confirm(title + 'のイベントを削除しますか？')
      if (result == true) {
        this.$axios
          .post('api/v1/members/delete', {
            id: this.targetId,
          })
          .then(() => {
            this.$router.go({ path: this.$router.currentRoute.path, force: true })
          })
          .catch((err) => {
            console.log('err:', err)
          })
      }
    },
    openEditModal: function (id, name, position, motto, file_name, history, content, display, sort) {
      window.scroll({ top: 0, behavior: 'smooth' })
      this.targetId = id
      this.updateName = name
      this.updatePosition = position
      this.updateMotto = motto
      this.updateFileName = file_name
      this.updateHistory = history
      this.updateContent = content
      this.updateDisplay = display
      this.updateSort = sort
      this.overlayDisplay = true
      this.editModalDisplay = true
      if (this.updateDisplay == 1) {
        this.updateChecked = true
      } else {
        this.updateChecked = false
      }
    },
    editDisplay: function () {
      if (!this.updateName) {
        alert('名前を入力してください。')
      } else if (!this.updatePosition) {
        alert('ポジションを入力してください。')
      } else if (!this.updateMotto) {
        alert('座右の銘を入力してください。')
      } else if (!this.updateFileName) {
        alert('画像URLを入力してください。')
      } else if (this.updateSort === '') {
        alert('ソートを入力してください。')
      } else if (!this.updateHistory) {
        alert('経歴を入力してください。')
      } else if (!this.updateContent) {
        alert('iRupへの想いを入力してください。')
      } else {
        if (this.updateChecked == true) {
          this.updateDisplay = 1
        } else {
          this.updateDisplay = 0
        }
        this.$axios
          .post('api/v1/members/update', {
            id: this.targetId,
            name: this.updateName,
            position: this.updatePosition,
            motto: this.updateMotto,
            file_name: this.updateFileName,
            history: this.updateHistory,
            content: this.updateContent,
            display: this.updateDisplay,
            sort: this.updateSort,
          })
          .then((res) => {
            this.posts = res.data.posts
            this.complitedDisplay = true
            this.editModalDisplay = false
            window.scroll({ top: 0, behavior: 'smooth' })
          })
          .catch((err) => {
            alert('編集に失敗しました。')
            console.log('err:', err)
          })
      }
    },
    backEditModal: function () {
      this.overlayDisplay = false
      this.editModalDisplay = false
    },
  },
  async created() {
    await this.$axios
      .get('/api/v1/members/list')
      .then((response) => {
        this.membersList = response.data
      })
      .catch((err) => {
        console.log('err:', err)
      })
  },
}
</script>

<style lang="scss" scoped>
.employee-post-page-wrap {
  background-color: #f5f5f5;
  font-family: 'Hiragino Sans';
  position: relative;
}

.employee-post-page {
  margin: 0 auto;
  max-width: 1080px;
  padding: 220px 10% 200px;
}

.nav-list {
  display: flex;
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.nav-item-btn {
  background-color: #fd9603;
  border-radius: 6px;
  color: #fff;
  cursor: default;
  display: block;
  font-size: 23px;
  height: 23px;
  padding: 13px 0;
  text-align: center;
  text-decoration: none;
  width: 192px;
}

.nav-item-wrap:nth-of-type(n + 2) {
  margin-left: 45px;
}

.nav-item-btn:hover {
  background-color: #c0c0c0;
}

.nav-item-btn:last-of-type {
  background-color: #c0c0c0;
}

.nav-item-btn:nth-of-type(n + 2) {
  margin-left: 50px;
}

.employee-post-form-wrap {
  background-color: #f9f9f9;
  margin-top: 50px;
  max-width: 1020px;
  padding: 70px 60px;
}

.employee-heading {
  font-size: 15px;
  width: 100px;
}

.employee-title-field {
  margin: 0;
  max-width: 540px;
  padding: 0;
}

.employee-title-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.employee-title-input::placeholder {
  color: transparent;
}

.employee-motto-field {
  margin-top: 40px;
  max-width: 540px;
  padding: 0;
}

.employee-motto-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.employee-motto-input::placeholder {
  color: transparent;
}

.employee-imageURL-field {
  margin-top: 40px;
  max-width: 540px;
  padding: 0;
}

.employee-imageURL-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.employee-imageURL-input::placeholder {
  color: transparent;
}

.employee-display-switch {
  margin-left: 100px;
  position: relative;
  width: 200px;
}

.toggle-btn {
  margin-top: 40px;
  position: relative;
}

.toggle-btn-base {
  background-color: #c3c3c3;
  border-radius: 30px;
  height: 33px;
  transition: 0.5s;
  width: 100px;
}

input[type='checkbox'] {
  position: absolute;
}

#switch {
  display: none;
}

.toggle-btn-circle {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  height: 27px;
  left: 68px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 27px;
}

input:checked ~ .toggle-btn-base {
  background-color: #fab805;
  transition: 0.5s;
}

input:checked ~ .toggle-btn-circle {
  translate: -62px;
}

.employee-history-field {
  height: 400px;
  margin-top: 40px;
  max-width: 926px;
  padding: 0;
}

.employee-history-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 400px;
  margin-top: 15px;
  padding: 0;
  resize: none;
  width: 100%;
}

.employee-history-input::placeholder {
  color: transparent;
}

.employee-textarea-field {
  height: 400px;
  margin-top: 80px;
  max-width: 926px;
  padding: 0;
}

.employee-textarea-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 400px;
  margin-top: 15px;
  padding: 0;
  resize: none;
  width: 100%;
}

.employee-textarea-input::placeholder {
  color: transparent;
}

.post-btn {
  background-color: #ff7300;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: block;
  font-size: 23px;
  font-weight: 500;
  margin: 100px 0 0 auto;
  padding: 10px 48px;
}

.post-btn:hover {
  background-color: #707070;
}

.past-employee-heading {
  font-size: 30px;
  font-weight: 300;
  margin: 170px 0 0;
}

.past-employee-wrap {
  background-color: #f9f9f9;
  margin-top: 20px;
  max-width: 1030px;
  padding: 30px 50px 40px;
}

.past-employee-list {
  padding: 0;
}

.past-employee-item {
  align-items: center;
  border-bottom: 1px solid;
  display: flex;
  height: 100px;
  list-style: none;
}

.past-employee-title {
  background-color: transparent;
  font-size: 15px;
  margin: 0 0 0 15px;
  pointer-events: none;
  width: 80px;
  word-wrap: break-word;
}

.past-employee-date {
  margin-left: 15px;
  width: 90px;
}

.display-mark {
  background-color: #fff;
  border: 1px solid #fd9603;
  border-radius: 5px;
  color: #fd9603;
  font-size: 14px;
  font-weight: 100;
  margin-left: 15px;
  padding: 5px 0;
  text-align: center;
  width: 80px;
}

.edit-btn {
  background-color: #ff6e00;
  border: none;
  border-radius: 7px;
  color: #fff;
  font-size: 20px;
  margin-left: auto;
  padding: 9px 0;
  text-align: center;
  width: 110px;
}

.edit-btn:hover {
  background-color: #707070;
}

.delete-btn {
  background-color: #ff6e00;
  border: none;
  border-radius: 7px;
  color: #fff;
  font-size: 20px;
  margin-left: 20px;
  padding: 9px 0;
  text-align: center;
  width: 110px;
}

.delete-btn:hover {
  background-color: #707070;
}

#overlay {
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.jump-click {
  text-decoration: none;
}

.employee-confirm-wrap {
  height: 100%;
  margin-top: 440px;
  padding: 0 50px;
  width: 100%;
}

.employee-confirm {
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  max-height: 1372px;
  max-width: 1009px;
  padding: 63px 71px 100px;
}

.employee-confirm-alert-wrap {
  border-bottom: 1px solid #707070;
}

.employee-confirm-alert {
  font-size: 25px;
  margin: 0 0 40px;
}

.employee-confirm-display {
  background-color: #fff;
  border: 1px solid #fd9603;
  border-radius: 5px;
  color: #fd9603;
  font-size: 14px;
  font-weight: 100;
  margin-top: 50px;
  padding: 5px 0;
  text-align: center;
  width: 80px;
}

.employee-confirm-heading {
  margin-top: 50px;
}

.employee-confilm-text {
  font-size: 15px;
  margin-top: 20px;
}

.employee-confilm-motto {
  font-size: 15px;
  margin-top: 20px;
}

.employee-confirm-attachment {
  align-items: center;
  background-color: #e5e5e5;
  border: 1px solid #707070;
  display: flex;
  font-size: 25px;
  height: 324px;
  justify-content: center;
  margin-top: 40px;
  width: 532px;
}

.employee-confirm-textarea {
  margin-top: 20px;
  white-space: pre-wrap;
}

.employee-confirm-btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.back-form-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  padding: 10px 0;
  width: 192px;
}

.back-form-button:hover {
  background-color: #707070;
}

.employee-confilm-post-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  margin-left: 50px;
  padding: 10px 0;
  width: 192px;
}

.employee-confilm-post-button:hover {
  background-color: #707070;
}

.post-completion-wrap {
  background-color: #fff;
  border-radius: 20px;
  height: 497px;
  margin-top: 450px;
  padding: 0;
  width: 745px;
  z-index: 11;
}

.post-completion {
  align-items: center;
  justify-content: center;
  margin: 185px auto;
  width: 300px;
}

.completion-message {
  font-size: 27px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-align: center;
}

.close-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  margin: 50px;
  padding: 10px 0;
  width: 192px;
}

.close-button:hover {
  background-color: #707070;
}

.edit-modal-wrap {
  height: 100%;
  margin-top: 440px;
  padding: 0 50px;
  width: 100%;
}

.edit-modal {
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  max-width: 1009px;
  padding: 63px 71px;
}

.edit-title-field {
  margin: 0;
  max-width: 540px;
  padding: 0;
}

.edit-heading {
  font-size: 15px;
  width: 100px;
}

.edit-title-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.edit-title-input::placeholder {
  color: transparent;
}

.edit-flex {
  display: flex;
  margin-top: 40px;
}

.edit-date-field {
  position: relative;
}

.edit-date-check {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 15px;
  height: 31px;
  margin-top: 15px;
  padding: 0 5px;
  width: 170px;
}

.edit-date-check::-webkit-calendar-picker-indicator {
  background: transparent;
  z-index: 2;
}

.edit-date-check::after {
  border-color: #000 transparent transparent;
  border-style: solid;
  border-width: 12px 7.5px 0;
  content: '';
  height: 0;
  position: absolute;
  right: 10px;
  width: 0;
}

.edit-display-switch {
  margin-left: 70px;
  position: relative;
  width: 200px;
}

.edit-toggle-btn {
  margin-top: 40px;
  position: relative;
}

.edit-toggle-btn-base {
  background-color: #c3c3c3;
  border-radius: 30px;
  height: 33px;
  transition: 0.5s;
  width: 100px;
}

input[type='checkbox'] {
  position: absolute;
}

#edit-switch {
  display: none;
}

.edit-toggle-btn-circle {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  height: 27px;
  left: 68px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 27px;
}

input:checked ~ .edit-toggle-btn-base {
  background-color: #fab805;
  transition: 0.5s;
}

input:checked ~ .edit-toggle-btn-circle {
  translate: -62px;
}

.edit-textarea-field {
  height: 400px;
  margin-top: 40px;
  max-width: 926px;
  padding: 0;
}

.edit-textarea-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 90%;
  margin-top: 15px;
  padding: 0;
  resize: none;
  width: 100%;
}

.edit-textarea-input::placeholder {
  color: transparent;
}

.edit-modal-btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.edit-modal-link {
  text-decoration-line: none;
}

.edit-modal-back-btn {
  align-items: center;
  background-color: #ff7300;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 23px;
  font-weight: 500;
  height: 45px;
  justify-content: center;
  width: 185px;
}

.edit-modal-btn {
  align-items: center;
  background-color: #ff7300;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 23px;
  font-weight: 500;
  height: 45px;
  justify-content: center;
  margin-left: 50px;
  width: 185px;
}

.employee-display-toggle-sort {
  display: flex;
  margin-top: 30px;
}

.employee-sort-input {
  margin-top: 30px;
}
</style>
