<script setup></script>
<template>
  <section>
    <div class="title-wrapper">
      <div>
        <h3 class="title-main">OUTLINE</h3>
        <h4 class="title-sub">募集要項</h4>
      </div>
    </div>
    <div class="outline-wrapper">
      <div class="outline-item">
        <div class="outline-title">雇用形態</div>
        <div class="outline-description">正社員</div>
      </div>
      <div class="outline-item">
        <div class="outline-title">給与</div>
        <div class="outline-description">
          <p>年棒</p>
          <p>3,000,000円 〜 7,200,000円</p>
        </div>
      </div>
      <!-- SP版 -->
      <div class="sp-view">
        <div class="outline-item">
          <p>＜年収例＞</p>
          <p>フロントエンジニア</p>
          <p>●年収320万円／入社1年目／25歳男性</p>
          <p>フロントエンジニア兼WEBデザイナー</p>
          <p>●年収400万円／入社2年目／28歳女性</p>
          <p>フロント兼バックエンドエンジニア</p>
          <p>●年収520万円／入社3年目／24歳男性</p>
        </div>
        <div class="outline-item">
          <p>＜月収例＞</p>
          <div class="month-salary">
            <div class="month-salary-title">・未経験者</div>
            <div class="month-salary-description">
              <span>24万円〜</span>
              <span>＋その他</span>
              <span>インセンティブ</span>
              <span>＋超過残業代</span>
              <span>＋交通費</span>
            </div>
          </div>
          <p>※契約社員からのスタート</p>
        </div>
        <div class="outline-item">
          <div class="month-salary">
            <div class="month-salary-title">・経験者</div>
            <div class="month-salary-description">
              <span>30万円〜60万円</span>
              <span>＋その他インセンティブ</span>
            </div>
          </div>
          <p>※正社員からのスタート</p>
        </div>
      </div>
      <!-- PC版 -->
      <div class="pc-view">
        <div class="outline-item mt-3">
          <div class="outline-title"></div>
          <div class="outline-description">
            <p>＜年収例＞</p>
            <p>●年収320万円／入社1年目／25歳男性 フロントエンジニア</p>
            <p>●年収400万円／入社2年目／28歳女性 フロントエンジニア兼WEBデザイナー</p>
            <p>●年収520万円／入社3年目／24歳男性 フロント兼バックエンドエンジニア</p>
          </div>
        </div>
        <div class="outline-item mt-3">
          <div class="outline-title"></div>
          <div class="outline-description">
            <p>＜月収例＞</p>
            <div class="month-salary">
              <div class="month-salary-title">・未経験者</div>
              <div class="month-salary-description">
                <span>24万円〜</span>
                <span>＋その他</span>
                <span>インセンティブ</span>
                <span>＋超過残業代</span>
                <span>＋交通費</span>
              </div>
            </div>
            <p>※契約社員からのスタート</p>
            <div class="month-salary">
              <div class="month-salary-title">・経験者</div>
              <div class="month-salary-description">
                <span>24万円〜</span>
                <span>＋その他インセンティブ</span>
                <span>＋超過残業代</span>
                <span>＋交通費</span>
              </div>
            </div>
            <p>※正社員からのスタート</p>
          </div>
        </div>
      </div>
      <div class="outline-item">
        <div class="outline-title">福利厚生</div>
        <div class="outline-description">
          <div class="sp-view">
            <p>雇用保険/厚生年金/労災保険</p>
            <p>健康保険/交通費支給あり</p>
            <p>資格取得支援・手当あり</p>
            <p>交流会/教育研修制度</p>
            <p>参考書代補助</p>
            <p>その他報奨金制度あり</p>
          </div>
          <div class="pc-view">
            <p>雇用保険/厚生年金/労災保険/健康保険/交通費支給あり/資格取得支援・手当あり</p>
            <p>交流会/教育研修制度/参考書代補助/その他報奨金制度あり</p>
          </div>
        </div>
      </div>
      <!-- SP版 -->
      <div class="outline-item sp-view">
        <div class="outline-title">休暇休日</div>
        <div class="outline-description">週休二日制</div>
      </div>
      <div class="sp-view">
        <div class="outline-item">
          <p>年末年始休暇 / 夏季休暇</p>
          <p>有給休暇（有休消化率80%以上）</p>
          <p>年間休日120日以上 / 慶弔休暇</p>
          <p>急なお休み考慮OK</p>
        </div>
      </div>
      <!-- PC版 -->
      <div class="outline-item pc-view">
        <div class="outline-title">休暇休日</div>
        <div class="outline-description">
          <p class="icon-paragraph">
            <span><img src="@/assets/img/checkbox_icon.svg" alt="チェックボックスアイコン" /></span>年間休日120日以上
            <span><img src="@/assets/img/checkbox_icon.svg" alt="チェックボックスアイコン" /></span>夏季休暇
            <span><img src="@/assets/img/checkbox_icon.svg" alt="チェックボックスアイコン" /></span>年末年始休暇
          </p>
          <p>年末年始休暇 / 夏季休暇 / 有給休暇（有休消化率80%以上）</p>
          <p>年間休日120日以上 / 慶弔休暇 / 急なお休み考慮OK</p>
        </div>
      </div>
      <div class="outline-item pc-view mt-3">
        <div class="outline-title"></div>
        <div class="outline-description">
          <p>各種手当</p>
          <p>◇交通費支給 ※プロジェクトにより異なる</p>
          <p>◇役職手当</p>
          <p>◇紹介手当</p>
        </div>
      </div>
      <div class="sp-view">
        <div class="outline-item">
          <p>各種手当</p>
          <p>◇交通費支給 ※プロジェクトにより異なる</p>
          <p>◇役職手当</p>
          <p>◇紹介手当</p>
        </div>
      </div>
      <div class="outline-item">
        <div class="outline-title">応募資格</div>
        <div class="outline-description">未経験可</div>
      </div>
      <div class="outline-item">
        <div class="outline-title">求める人材</div>
        <div class="outline-description">
          <p>iRupでは、チャレンジ精神に溢れ、元気で前向きな人材を求めています。</p>
          <p>失敗を恐れず、新しいことに積極的に取り組める方は大歓迎です。</p>
          <p>共に成長し、新たな価値を創造する仲間として、あなたの力を発揮しましょう。</p>
        </div>
      </div>
    </div>
  </section>
</template>
<style scoped lang="scss">
/** ブレイクポイントの設定 */
@use '@/assets/sass/foundation/breakpoint' as bp;

/** font-familyやfont-weightの設定 */
@use '@/assets/sass/foundation/font' as font;

/** プロジェクトで使用するカラーコードは定義されるまでハードコード */

/** スマホファーストで記述 */

.pc-view {
  display: none;
  @include bp.mq(pc) {
    display: block;
  }
}

p {
  margin: 0;
}

.title {
  &-wrapper {
    display: flex;
    justify-content: center;
  }
  &-main {
    font-size: 15px;
    line-height: 1.5;
    margin: 0;
    @include font.genno(b);
    @include bp.mq(pc) {
      font-size: 40px;
    }
  }
  &-sub {
    align-items: center;
    display: flex;
    font-size: 11px;
    line-height: 1.5;
    margin: 0;
    @include font.genno(n);
    @include bp.mq(pc) {
      font-size: 20px;
    }
    &::before {
      background-color: #ff8f00;
      content: '';
      display: block;
      height: 3px;
      margin-right: 10px;
      width: 20px;
      @include bp.mq(pc) {
        height: 4px;
        width: 40px;
      }
    }
  }
}

.outline {
  &-wrapper {
    background-color: #fff;
    font-size: 12px;
    margin-top: 30px;
    // outline-itemのmargin-topを聞かせるため、padding-topに1pxを指定
    padding: 1px 20px 40px;
    @include font.genno(n);
    @include bp.mq(pc) {
      font-size: 20px;
      margin-top: 70px;
      padding: 1px 0 140px 140px;
    }
  }
  &-item {
    display: flex;
    line-height: 1.5;
    margin-top: 30px;
    @include bp.mq(pc) {
      margin-top: 60px;
    }
    &.mt-3 {
      margin-top: 30px;
    }
  }
  &-title {
    width: 35%;
    @include bp.mq(pc) {
      width: 15%;
    }
  }
  &-description {
    width: 65%;
    @include bp.mq(pc) {
      width: 85%;
    }
  }
}

.month-salary {
  display: flex;
  &-title {
    display: flex;
    justify-content: space-between;
    min-width: 6em;
    &::after {
      content: '：';
    }
  }
  &-description {
    display: inline-flex;
    flex-wrap: wrap;
  }
}

.icon-paragraph {
  color: #f90;
  display: flex;
  > span {
    display: block;
    width: 18px;
    &:nth-child(n + 2) {
      margin-left: 18px;
    }
  }
}

.sp-view {
  @include bp.mq(pc) {
    display: none;
  }
  > .outline-item {
    display: block;
  }
}
</style>
