import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import BusinessContentView from '../views/BusinessContentView.vue'
import ControlEventView from '../views/ControlEventView.vue'
import EventView from '../views/EventView.vue'
import MemberView from '../views/MemberView.vue'
import NotFoundView from '../views/NotFoundView.vue'
import PostNewsView from '../views/PostNewsView.vue'
import PostEmployeeView from '../views/PostEmployeeView.vue'
import LoginPageView from '../views/LoginPageView.vue'
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue'
import RecruitView from '../views/RecruitView.vue'
import ContactView from '../views/ContactView.vue'
import ContactConfirmation from '../views/ContactConfirmation.vue'
import ContactThanks from '../views/ContactThanks.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { title: 'iRup' },
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView,
    meta: { title: '会社概要｜iRup' },
  },
  {
    path: '/business',
    name: 'businessContentView',
    component: BusinessContentView,
    meta: { title: '事業内容｜iRup' },
  },
  {
    path: '/event',
    name: 'event',
    component: EventView,
  },
  {
    path: '/member',
    name: 'memberView',
    component: MemberView,
    meta: { title: '社員紹介｜iRup' },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: { title: 'お問い合わせ入力｜iRup' },
  },
  {
    path: '/ContactConfirmation',
    name: 'contact-confirmation',
    component: ContactConfirmation,
    meta: { title: 'お問い合わせ確認｜iRup' },
  },
  {
    path: '/ContactThanks',
    name: 'contact-thanks',
    component: ContactThanks,
    meta: { title: 'お問い合わせ完了｜iRup' },
  },
  {
    path: '/404',
    name: 'notFound',
    component: NotFoundView,
    meta: { title: '404エラー' },
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
  {
    path: '/controlEvent',
    name: 'controlEvent',
    component: ControlEventView,
    meta: {
      title: 'イベントページ｜iRup',
      requiresAuth: true, // 認証済の時のみ閲覧可能となるように定義
    },
  },
  {
    path: '/login',
    name: 'LoginPageView',
    component: LoginPageView,
    meta: { title: 'ログインページ｜iRup' },
  },
  {
    path: '/postnews',
    name: 'postnewsView',
    component: PostNewsView,
    meta: {
      title: '管理画面（ニュース）｜iRup',
      requiresAuth: true, // 認証済の時のみ閲覧可能となるように定義
    },
  },
  {
    path: '/postemployee',
    name: 'postemployeeView',
    component: PostEmployeeView,
    meta: {
      title: '管理画面（社員）｜iRup',
      requiresAuth: true, // 認証済の時のみ閲覧可能となるように定義
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacypolicyView',
    component: PrivacyPolicyView,
    meta: { title: 'プライバシーポリシー' },
  },
  {
    path: '/recruit',
    name: 'RecruitView',
    component: RecruitView,
    meta: { title: '採用情報' },
  },
]

const router = new createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (to.name === 'home' && from.name === 'home') {
      return { top: 0, behavior: 'smooth' }
    } else if (to.hash) {
      return {
        el: to.hash,
        top: 100,
      }
    } else {
      return { top: 0 }
    }
  },
})
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 認証されていない時、認証画面へ遷移
    if (!sessionStorage.getItem('login')) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
        },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
