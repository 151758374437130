<template>
  <header class="header">
    <router-link class="logo" to="/">
      <img class="logo-image" src="../assets/img/iRup-logo.png" alt="ロゴ" title="logo" />
    </router-link>
    <ul class="items">
      <div class="item" v-for="item in items" :key="item.index">
        <router-link :to="item.pass">
          {{ item.name }}
        </router-link>
      </div>
    </ul>
    <ul class="sns-logos">
      <li class="sns-logo">
        <a href="https://www.instagram.com/irup0113/?hl=ja" target="_blank">
          <img class="instagram-logo-image" src="../assets/img/Instagram_header.png" alt="Instagramのロゴ" />
        </a>
      </li>
      <li class="sns-logo">
        <a href="https://www.tiktok.com/@irup0113?lang=ja-JP" target="_blank">
          <img class="tiktok-logo-image" src="../assets/img/tiktok_header.svg" alt="TikTokのロゴ" />
        </a>
      </li>
    </ul>
  </header>
</template>
<script>
export default {
  data() {
    return {
      items: [
        {
          name: '企業概要',
          class: 'about-menu',
          pass: '/about',
        },
        {
          name: '事業内容',
          class: 'business-menu',
          pass: '/business',
        },
        {
          name: '採用情報',
          class: 'recruit-member',
          pass: '/recruit',
        },
        {
          name: 'お問い合わせ',
          class: 'contact-menu',
          pass: '/contact',
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');

.hover-orange {
  position: relative;
  text-decoration: none;
}

.hover-orange:hover {
  border-bottom: 2px solid #ef9b38;
  color: #707070;
  cursor: default;
}

.header {
  align-items: center;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 37px 36px 29px 38px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  font-family: 'Noto Sans JP', sans-serif;
}

.logo {
  height: 39px;
  width: 116px;
}

.logo-image {
  height: 100%;
}

.sns-logos {
  margin: 0;
  padding: 0;
}

.sns-logo {
  list-style: none;
}

.instagram-logo-image {
  height: 27px;
  object-fit: cover;
  position: absolute;
  right: 110px;
  top: -12px;
  width: 27px;
}

.tiktok-logo-image {
  height: 27px;
  object-fit: cover;
  position: absolute;
  right: 60px;
  top: -30px;
  width: 27px;
}

.nav {
  color: #000;
  font-family: 'Noto Sans Japanese', sans-serif;
  font-size: 14px;
}

.items {
  display: flex;
  list-style-type: none;
  position: absolute;
  right: 200px;
  top: 28px;
  align-items: center;
  font-size: 14px;
}

.item + .item {
  padding-left: 60px;
}
.item:nth-child(4) a {
  padding: 6px 7px;
  color: #ff6700;
  border: 1px solid #ff6700;
  border-radius: 7px;
  font-size: 12px;
  width: 126px;
  text-align: center;
  &:hover {
    background-color: #ff6700;
    color: #fff;
  }
}
.dropdown + .dropdown {
  margin-top: 15px;
}

.dropdowns {
  background-color: #fff;
  box-shadow: 0 10px 25px 0 rgb(0 0 0 / 50%);
  padding: 40px;
  position: fixed;
  text-align: center;
}

.irup-menu {
  right: 350px;
  top: 61px;
}

.member-menu {
  right: 230px;
  top: 61px;
}

.contact-menu {
  right: 100px;
  top: 61px;
}

a {
  color: #000;
  cursor: pointer;
  display: inline-block;
  position: relative;
  text-align: left;
  text-decoration: none;
}

a:hover {
  color: #9a9a9a;
}

.header__nav.active {
  transform: translateX(0);
}
</style>
