<script setup></script>
<template>
  <div class="arrow-wrapper">
    <div class="arrow"></div>
  </div>
</template>
<style scoped lang="scss">
/** ブレイクポイントの設定 */
@use '@/assets/sass/foundation/breakpoint' as bp;

/** font-familyやfont-weightの設定 */
@use '@/assets/sass/foundation/font' as font;

/** プロジェクトで使用するカラーコードは定義されるまでハードコード */

/** スマホファーストで記述 */

.arrow {
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 25px solid #ff5900;
  @include bp.mq(pc) {
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-top: 50px solid #ff5900;
  }
  &-wrapper {
    display: flex;
    justify-content: center;
  }
}
</style>
