<template>
  <div class="PCmenu">
    <HeaderMenu />
  </div>
  <div class="SPmenu">
    <HamburgerMenu />
  </div>
</template>

<script>
import HamburgerMenu from '@/components/HamburgerMenu.vue'
import HeaderMenu from '@/components/HeaderMenu.vue'

export default {
  components: {
    HeaderMenu,
    HamburgerMenu,
  },
}
</script>

<style lang="scss" scoped>
@media screen and (min-width: 796px) {
  .SPmenu {
    display: none;
  }
}

@media screen and (max-width: 795px) {
  .PCmenu {
    display: none;
  }
}
</style>
