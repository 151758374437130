<template>
  <div class="contact-image">
    <h1 class="contact-title">CONTACT</h1>
    <h2 class="sub-contact-title">お問い合わせ</h2>
  </div>
  <div class="all-wrapper">
    <div class="wrapper">
      <div class="flow-step">
        <div class="flow-step-list">
          <p class="step-status" v-for="step in steps" :key="step.id">{{ step.status }}</p>
        </div>
      </div>
      <p class="submit-ok-sentence">送信が完了いたしました。<br />お問い合わせありがとうございます。</p>
      <div class="button">
        <input onclick="location.href='/'" class="top-page-button" type="button" value="TOPへ戻る" @click="send" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      steps: [
        {
          status: '入力',
        },
        {
          status: '確認',
        },
        {
          status: '完了',
        },
      ],
    }
  },
  methods: {
    send() {
      // 送信処理
      localStorage.removeItem('formData')
    },
  },
}
</script>
<style lang="scss" scoped>
.contact-image {
  background-image: url('/src/assets/img/headline.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  margin-top: 100px;
  .contact-title {
    position: relative;
    font-size: 70px;
    margin: 0;
    padding: 132px 0 0 91px;
    color: #fd9602;
    bottom: 7px;
  }
  .sub-contact-title {
    color: #000000;
    font-size: 25px;
    margin: 0 0 0 177px;
    font-weight: normal;
  }
  .sub-contact-title::before {
    width: 70px;
    border: #ff5900 5px solid;
    position: relative;
    content: '';
    margin: 0 60px;
    right: 150px;
    top: 18px;
    display: flex;
  }
}
.all-wrapper {
  background: url('../assets/img/about-bg.jpg');
  padding: 9% 18%;
  padding-top: 250px;
}
.wrapper {
  background-color: #fff;
  padding: 8% 12% 53px 12%;
  width: unset;
  font-family: 'source-han-sans-japanese';
}
.page-header {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.page-header + form {
  margin-top: 80px;
}

.flow-step {
  margin-top: 32px;
  text-align: -webkit-center;
  .flow-step-list {
    border-bottom: 2px solid #ff7300;
    display: flex;
    justify-content: center;
    width: 300px;
    .step-status {
      color: #b7b7b7;
      font-weight: bold;
      margin: 0 25px 10.5px 0;
    }
    .step-status:nth-child(3) {
      color: #ff6700;
    }
    .step-status:nth-child(3) {
      margin-right: 0;
    }
    .step-status::after {
      content: '>';
      margin-left: 17px;
    }
    .step-status:nth-child(3)::after {
      content: '';
    }
  }
}
.submit-ok-sentence {
  margin-top: 95px;
  text-align: center;
}
.button {
  text-align: center;
  margin-top: 91px;
  font-size: 21px;
  .top-page-button {
    background-color: #ffffff;
    color: #ff7300;
    font-weight: bold;
    border: 1px solid #ff7300;
    border-radius: 6px;
    padding: 10px 69.1px;
  }
  .top-page-button:hover {
    background-color: #ff7300;
    color: #ffffff;
    cursor: pointer;
  }
}
@media screen and (max-width: 795px) {
  .contact-image {
    height: 75px;
    margin-top: 70px;
    .contact-title {
      font-size: 25px;
      padding: 46px 0 0 30px;
      bottom: 4px;
    }
    .sub-contact-title {
      font-size: 13px;
      margin: 0 0 0 91px;
    }
    .sub-contact-title::before {
      width: 40px;
      border: #ff5900 3px solid;
      margin: 0 0;
      right: 60px;
      top: 10px;
    }
  }
  .all-wrapper {
    background: none;
    padding: 0;
  }
  .contact {
    padding: 0;
  }
  .flow-step {
    margin-top: 49px;
    .flow-step-list {
      width: 248px;
    }
  }
  .submit-ok-sentence {
    margin-top: 53.67px;
    font-size: 12px;
  }
  .button {
    font-size: 17px;
    margin-top: 54px;
    .top-page-button {
      padding: 6px 24.1px;
    }
  }
}
</style>
