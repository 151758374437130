<template>
  <main>
    <div class="p-error">
      <div class="p-error__img-wrap">
        <img src="@/assets/img/iRup-logo.png" alt="iRupのロゴ" />
      </div>
      <h1 class="p-error__ttl">404エラー</h1>
      <p class="p-error__txt">お探しのページは見つかりませんでした。</p>
      <div class="p-error__btn-wrap">
        <router-link to="/" class="p-error__top-btn">トップページへ</router-link>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'NotFund',
}
</script>

<style lang="scss" scoped>
.p-error {
  box-sizing: border-box;
  font-family: source-han-sans-japanese, sans-serif;
  font-style: normal;
  font-weight: 400;

  @media only screen and (max-width: 795px) {
    height: calc(100vh - 140px);
    padding: 140px 7% 0;
  }

  @media only screen and (min-width: 796px) {
    height: calc(100vh - 240px);
    padding: 10% 4% 0;
  }
  &__ttl {
    color: #333;
    text-align: center;

    @media only screen and (max-width: 795px) {
      font-size: 28px;
    }

    @media only screen and (min-width: 796px) {
      font-size: 46px;
    }
  }
  &__txt {
    text-align: center;

    @media only screen and (max-width: 795px) {
      font-size: 14px;
    }

    @media only screen and (min-width: 796px) {
      font-size: 20px;
    }
  }
  &__img-wrap {
    margin: 0 auto;

    @media only screen and (min-width: 796px) {
      max-width: 300px;
    }

    @media only screen and (max-width: 795px) {
      max-width: 200px;
    }
    img {
      width: 100%;
    }
  }
  &__btn-wrap {
    margin-top: 32px;
    text-align: center;
  }
  &__top-btn {
    background-color: #ff7300;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    padding: 1.5em 2.8em;
    text-decoration: none;
    transition: 0.4s;

    @media only screen and (max-width: 795px) {
      font-size: 14px;
    }

    @media only screen and (min-width: 796px) {
      font-size: 16px;
    }
    &:hover {
      background-color: #333;
    }
  }
}
</style>
