<template>
  <HeaderComponent />
  <router-view />
  <ContactFooter v-if="$route.path.match(/contact|ContactConfirmation|ContactThanks/)" />
  <FooterComponent v-else />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'
import ContactFooter from '@/components/ContactFooter.vue'
export default {
  components: {
    HeaderComponent,
    FooterComponent,
    ContactFooter,
  },
  methods: {
    setMeta(route) {
      if (route.meta.title) {
        let setTitle = route.meta.title
        document.title = setTitle
      }
    },
  },
  mounted() {
    let route = this.$route
    this.setMeta(route)
  },
  watch: {
    $route(route) {
      this.setMeta(route)
    },
  },
}
</script>

<style lang="scss"></style>
