<template>
  <footer class="footer">
    <div class="contact-block">
      <div
        class="link-block"
        v-if="
          !(this.$route.name === 'contactView') &&
          !(this.$route.name === 'postnewsView') &&
          !(this.$route.name === 'postemployeeView') &&
          !(this.$route.name === 'editemployeeView') &&
          !(this.$route.name === 'editeventView')
        "
      >
        <p class="link-block-text">事業についての質問や、お問い合わせはこちらから</p>
        <router-link to="/contact" class="contact-btn">
          <p class="contact-btn-text">CONTACT</p>
        </router-link>
      </div>
    </div>
    <div class="footer_contents">
      <div class="wrap">
        <div class="navigation">
          <ul class="menu_list">
            <li v-for="nav_menu in menues" :key="nav_menu.id">
              <router-link :to="nav_menu.path" class="link">{{ nav_menu.name }}</router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="address-block">
        <div class="logo-and-copyright">
          <img class="iRup-logo" src="../assets/img/iRup-logo-footer.png" />
          <small class="copyright">&copy;iRup All Rights Reserved.</small>
        </div>
        <div class="address">
          <div class="head-office">
            <img class="map-icon" src="../assets/img/map_icon.svg" />
            <p class="head_address">東京本社</p>
          </div>
          <p class="company_address">東京都港区三田5-21-6</p>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      icons: [
        {
          sns_button: require('../assets/img/instagram.png'),
          url: 'https://www.instagram.com/irup0113/?hl=ja',
        },
        {
          sns_button: require('../assets/img/tiktok.png'),
          url: 'https://www.tiktok.com/@irup0113?lang=ja-JP',
        },
      ],
      menues: [
        {
          name: '企業概要',
          path: '/about',
        },
        {
          name: '事業内容',
          path: '/business',
        },
        {
          name: '採用情報',
          path: '/recruit',
        },
        {
          name: 'プライバシーポリシー',
          path: '/privacy-policy',
        },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
.footer {
  font-family: 'Noto Sans Japanese', '源ノ角ゴシック JP';
}

.map-icon {
  width: 18px;
}

.link-block {
  background-color: rgb(178 89 0 / 63%);
  height: 504px;
  position: relative;
  .link-block-text {
    margin-top: 0;
    color: #fff;
    font-size: 22px;
    padding-top: 167px;
    text-align: center;
  }
  .contact-btn {
    text-decoration: none;
    display: block;
    align-self: center;
    background-color: #fff;
    border: 2px solid #ff6700;
    border-radius: 10px;
    color: #ff6700;
    margin: 0 auto;
    margin-top: 90px;
    width: 33%;
    .contact-btn-text {
      font-size: 33px;
      letter-spacing: 7.92px;
      margin: 0;
      padding: 28px 10%;
      text-align: center;
    }
  }
  .contact-btn:hover {
    background-color: #ff6700;
    color: #fff;
  }
}

.link-block::after {
  background: url('../assets/img/contact.jpg') no-repeat;
  background-size: cover;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.footer_contents {
  background: #000;
  color: #fff;
  font-family: Arial;
  height: 437px;
  .wrap {
    align-items: center;
    border-bottom: solid 1px #707070;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 5% 0;
    width: 80%;
    .navigation {
      align-items: center;
      display: flex;
      margin: 0 auto;
      .menu_list {
        display: inline-block;
        position: relative;
        li {
          display: inline-block;
          .link {
            color: #fff;
            font-size: 20px;
            margin: 0 70px 0 0;
            text-decoration: none;
          }
          .link:hover {
            color: #707070;
          }
        }
      }
    }
  }
  .address-block {
    display: flex;
    justify-content: space-between;
    margin: 50px auto 100px;
    width: 70%;
    align-items: center;
    .logo-and-copyright {
      margin-left: 13%;
      .iRup-logo {
        height: 50px;
        object-fit: fill;
        width: 150px;
      }
      .copyright {
        align-items: center;
        display: block;
        font-size: 14px;
        justify-content: center;
        letter-spacing: 0.8pt;
        text-align: center;
      }
    }
    .address {
      font-size: 18px;
      margin-right: 13%;

      .head-office {
        display: flex;
        .head_address {
          margin: 0;
          width: 120%;
          margin-left: 10px;
        }
      }
      .company_address {
        margin: 10px 0 0 0;
      }
    }
  }
}

@media screen and (max-width: (966px)) {
  .map-icon {
    width: 15px;
  }
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              color: #fff;
              font-size: 20px;
              margin: 0 30px 0 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (803px)) {
  .map-icon {
    width: 9px;
  }
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              color: #fff;
              font-size: 20px;
              margin: 0 20px 0 0;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (795px)) {
  .link-block {
    height: 147px;
    .link-block-text {
      font-size: 12px;
      padding-top: 39px;
    }
    .contact-btn {
      border: 1px solid #ff6700;
      border-radius: 5px;
      margin: 0 auto;
      margin-top: 17px;
      .contact-btn-text {
        font-size: 14px;
        letter-spacing: 3.36px;
        padding: 9px 10%;
      }
    }
  }
  .footer_contents {
    height: 180px;
    .wrap {
      padding: 3% 0 1%;
      .navigation {
        .menu_list {
          li {
            .link {
              font-size: 10px;
              margin: 0 20px 0 0;
            }
          }
        }
      }
    }
    .address-block {
      margin: 22px auto 26px;
      .logo-and-copyright {
        margin-left: 13%;
        .iRup-logo {
          height: 30px;
          width: 85px;
        }
        .copyright {
          font-size: 7px;
          padding-top: 5px;
        }
      }
      .address {
        font-size: 8px;
        margin-right: 13%;
        .company_address {
          width: 120%;
        }
      }
    }
  }
}

@media screen and (max-width: (780px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 19px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (760px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 18px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (740px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 17px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (700px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 16px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (660px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 15px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (620px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 14px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (580px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 13px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (540px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 12px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (500px)) {
  .footer_contents {
    .wrap {
      .contents {
        padding-left: 0;
      }
      .navigation {
        .menu_list {
          padding-left: 0;
          li {
            .link {
              margin: 0 11px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (480px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 10px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (468px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 9px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (460px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 8px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (452px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 7px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (444px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 6px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (433px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 5px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (425px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 4px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (416px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 3.5px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (404px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 3px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (392px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 2.8px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (380px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 2.6px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (372px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 2.4px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (364px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 2.2px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (356px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 2px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (348px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 1.8px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (340px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 1.4px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (332px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 1.2px 0 0;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: (324px)) {
  .footer_contents {
    .wrap {
      .navigation {
        .menu_list {
          li {
            .link {
              margin: 0 1px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
