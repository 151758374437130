<template>
  <div v-if="length > 1" class="list-item list-item-nav">
    <span class="btn btn-prev" @click="$router.push(`?${query}=${now - 1 || 1}&cat=${cat}`)">＜</span>
    <ul class="list">
      <li :class="{ now: now === 1 }" class="item item-link" @click="$router.push(`?${query}=1&cat=${cat}`)">
        <span class="text"> 1 </span>
      </li>
      <template v-if="length > 2">
        <template v-if="length <= 5">
          <li :class="{ now: now === 2 }" class="item item-link" @click="$router.push(`?${query}=2&cat=${cat}`)">
            <span class="text"> 2 </span>
          </li>
          <template v-if="length > 3">
            <li :class="{ now: now === 3 }" class="item item-link" @click="$router.push(`?${query}=3&cat=${cat}`)">
              <span class="text"> 3 </span>
            </li>
            <template v-if="length > 4">
              <li :class="{ now: now === 4 }" class="item item-link" @click="$router.push(`?${query}=4&cat=${cat}`)">
                <span class="text"> 4 </span>
              </li>
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="now < 4">
            <li :class="{ now: now === 2 }" class="item item-link" @click="$router.push(`?${query}=2&cat=${cat}`)">
              <span class="text"> 2 </span>
            </li>
            <li :class="{ now: now === 3 }" class="item item-link" @click="$router.push(`?${query}=3&cat=${cat}`)">
              <span class="text"> 3 </span>
            </li>
            <li v-if="now === 3" class="item item-link" @click="$router.push(`?${query}=4&cat=${cat}`)">
              <span class="text"> 4 </span>
            </li>
            <li class="item item-dots">
              <div class="dot" />
              <div class="dot" />
              <div class="dot" />
            </li>
          </template>
          <template v-else-if="length <= now + 2">
            <li class="item item-dots">
              <div class="dot" />
              <div class="dot" />
              <div class="dot" />
            </li>
            <li
              v-if="now === length - 2"
              class="item item-link"
              @click="$router.push(`?${query}=${length - 3}&cat=${cat}`)"
            >
              <span class="text">
                {{ length - 3 }}
              </span>
            </li>
            <li
              :class="{ now: now === length - 2 }"
              class="item item-link"
              @click="$router.push(`?${query}=${length - 2}&cat=${cat}`)"
            >
              <span class="text">
                {{ length - 2 }}
              </span>
            </li>
            <li
              :class="{ now: now === length - 1 }"
              class="item item-link"
              @click="$router.push(`?${query}=${length - 1}&cat=${cat}`)"
            >
              <span class="text">
                {{ length - 1 }}
              </span>
            </li>
          </template>
          <template v-else>
            <li class="item item-dots">
              <div class="dot" />
              <div class="dot" />
              <div class="dot" />
            </li>
            <li class="item item-link" @click="$router.push(`?${query}=${now - 1}&cat=${cat}`)">
              <span class="text">
                {{ now - 1 }}
              </span>
            </li>
            <li class="item item-link now">
              <span class="text">
                {{ now }}
              </span>
            </li>
            <li class="item item-link" @click="$router.push(`?${query}=${now + 1}&cat=${cat}`)">
              <span class="text">
                {{ now + 1 }}
              </span>
            </li>
            <li class="item item-dots">
              <div class="dot" />
              <div class="dot" />
              <div class="dot" />
            </li>
          </template>
        </template>
      </template>
      <li
        :class="{ now: now === length }"
        class="item item-link"
        @click="$router.push(`?${query}=${length}&cat=${cat}`)"
      >
        <span class="text">
          {{ length }}
        </span>
      </li>
    </ul>
    <span class="btn btn-next" @click="$router.push(`?${query}=${now + 1 <= length ? now + 1 : length}&cat=${cat}`)">
      ＞
    </span>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true,
    },
    now: {
      type: Number,
      required: true,
    },
    cat: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  margin: 0 10px;
  &-item {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 150px 0;
  }
  &-item-nav {
    margin: 0 auto;
  }
}

.item {
  border: 1px solid #333;
  text-align: center;
  & + & {
    margin-left: 10px;
  }
  &-dots {
    align-items: center;
    border: none;
    display: flex;
    justify-content: center;
  }
  &-link {
    cursor: pointer;
  }
}

.now {
  background-color: #333;
  color: white;
}

.dot {
  position: relative;
  width: 6px;
  &::after {
    background-color: #333;
    border-radius: 50%;
    content: '';
    height: 2px;
    position: absolute;
    top: 0;
    width: 2px;
  }
}

.btn {
  cursor: pointer;
}

ul {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

@media screen and (min-width: 796px) {
  .list-item {
    margin: 150px 0;
  }
  .item {
    font-size: 20px;
    padding: 5px 8px;
  }
}

@media screen and (max-width: 795px) {
  .list-item {
    margin: 40px 0;
  }
  .item {
    font-size: 14px;
    padding: 2px 4px;
  }
}
</style>
