<template>
  <div class="header">
    <div class="logo">
      <router-link to="/">
        <img class="logo-image" src="../assets/img/iRup-logo.png" alt="ロゴ" title="logo" />
      </router-link>
    </div>
    <div class="hamburger-btn">
      <input type="checkbox" v-model="checked" id="menu-btn-check" class="check" />
      <label for="menu-btn-check" class="menu-btn" @click="openControl()">
        <span></span>
      </label>
    </div>
    <nav class="menu" v-bind:class="{ 'is-active': open }" v-on:click="openControlmenu()">
      <div class="link-item" v-for="link in links" :key="link.index">
        <router-link v-if="!link.URL" class="item" :to="link.pass">{{ link.name }}</router-link>
        <a v-else class="item" :href="link.URL">{{ link.name }}</a>
      </div>
      <ul class="sns-logos">
        <li class="sns-logo">
          <a href="https://www.instagram.com/irup0113/?hl=ja" target="_blank">
            <img class="instagram-logo-image" src="../assets/img/Instagram_header.png" alt="Instagramのロゴ" />
          </a>
        </li>
        <li class="sns-logo">
          <a href="https://www.tiktok.com/@irup0113?lang=ja-JP" target="_blank">
            <img class="tiktok-logo-image" src="../assets/img/tiktok_header.svg" alt="TikTokのロゴ" />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
      checked: false,
      links: [
        {
          name: 'HOME',
          pass: '/',
        },
        {
          name: '企業概要',
          pass: '/about',
        },
        {
          name: '事業内容',
          pass: '/business',
        },
        {
          name: '採用情報',
          pass: '/recruit',
        },
        {
          name: 'お問い合わせ',
          pass: '/contact',
        },
      ],
    }
  },
  methods: {
    openControl() {
      this.open = !this.open
    },
    openControlmenu() {
      this.open = !this.open
      this.checked = !this.checked
    },
  },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@700&display=swap');

.header {
  align-items: center;
  background-color: red;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 16px 30px 16px 26px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.logo {
  height: 28px;
  width: 70px;
}

.logo-image {
  height: 100%;
}

.menu-btn {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 auto;
  position: fixed;
  right: 20px;
  top: 16px;
  width: 40px;
  z-index: 999;
}

.menu-btn span,
.menu-btn span::before,
.menu-btn span::after {
  background-color: #7b7b7b;
  border-radius: 3px;
  border-radius: 3px;
  content: '';
  display: block;
  height: 2px;
  position: absolute;
  width: 25px;
}

.menu-btn span::before {
  bottom: 8px;
}

.menu-btn span::after {
  top: 8px;
}

.check {
  display: none;
}

#menu-btn-check:checked ~ .menu-btn span {
  background-color: rgb(255 255 255 / 0%);
}

#menu-btn-check:checked ~ .menu-btn span::before {
  bottom: 0;
  transform: rotate(45deg);
}

#menu-btn-check:checked ~ .menu-btn span::after {
  top: 0;
  transform: rotate(-45deg);
}

.menu {
  align-items: center;
  align-items: center;
  background: #fff;
  display: flex;
  display: none;
  flex-direction: column;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease-in-out;
  width: 100%;
}

.menu.is-active {
  margin-top: 44px;
  padding-top: 115px;
  display: inline;
  margin: 60px 0;
  pointer-events: auto;
}

.link-item {
  width: 100%;
  padding: 25px 45px;
  font-size: 14px;
  pointer-events: none;
  width: 100%;
}
.item {
  color: #8b8b8b;
  font-size: 14px;
  pointer-events: auto;
  text-decoration: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  position: relative;
  &::after {
    content: '';
    height: 2px;
    background-color: #ef9b38;
    position: absolute;
    width: 120px;
    left: 0px;
    bottom: 0px;
    visibility: hidden;
  }
  &:hover::after {
    visibility: visible;
  }
}

.sns-logo {
  list-style: none;
}

.instagram-logo-image {
  height: 27px;
  left: 45px;
  object-fit: cover;
  position: absolute;
  width: 27px;
}

.tiktok-logo-image {
  height: 27px;
  left: 97px;
  object-fit: cover;
  position: absolute;
  width: 27px;
}
</style>
