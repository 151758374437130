export const CEO_MESSAGE_INFO = [
  {
    title: 'MESSAGE',
    message: '代表メッセージ',
    potion: '代表取締役',
    name: '向井  一真',
    english: 'KAZUMA MUKAI',
    text: '皆様、こんにちは。株式会社iRupの社長、向井一真です。<br><br>私たちiRupは、「人が成長の階段を駆け上がる」を意味する社名を掲げ、お客様や社員が共に成長し続けられる会社を目指しております。私たちのサービスは、テックソリューション事業、受託開発事業、教育事業、自社開発事業の4つの柱で構成されており、それぞれの分野で最高のパフォーマンスを提供することに全力を注いでいます。<br><br> 技術革新が急速に進む現代社会では、絶えず新しい知識やスキルを身につけることが求められます。私たちiRupは、お客様のビジネスを成功に導くため、最新の技術動向に対応し、市場のニーズを的確に捉えることを重要視しています。<br><br> また、私たちの教育事業では、IT技術者の育成に力を入れています。優れた人材を育てることで、お客様に対して最高品質のサービスを提供できるだけでなく、社員一人ひとりの成長も促進されると確信しています。私たちのミッションは、社員の力を最大限に引き出し、お客様の期待を超える結果を生み出すことです。<br><br> iRupの取り組みを通じて、お客様のビジネスが飛躍的に成長する姿を見ることが、私たちの最大の喜びです。これからも、お客様と共に新たな高みへ駆け上がっていくことをお約束します。<br><br> 今後ともiRupをよろしくお願い申し上げます。<br><br> iRup株式会社 代表取締役 <br>向井一真',
  },
]

export const IRUP_CORPORATE_INFO = [
  {
    item: '社名',
    content: '株式会社 iRup (iRup inc.)',
  },
  {
    item: '住所',
    content: '東京都港区三田5-21-6<br>大阪府中央区南船場3-7-27<br>愛知県名古屋市中区新栄1丁目46-5',
  },

  {
    item: '代表',
    content: '代表取締役 向井 一真',
  },
  {
    item: '電話番号',
    content: '03-4400-2448',
  },
  {
    item: '資本金',
    content: '5,000,000円',
  },
  {
    item: '設立',
    content: '2021年1月',
  },
  {
    item: '事業許可番号',
    content: '労働者派遣事業許可番号「派 13-316899」',
  },
]

export const IRUP_CORPORATE_MAPS = [
  {
    id: 1,
    location: '東京拠点',
    zipCode: '〒108-0073',
    address: '東京都港区三田5-21-6',
    src: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6484.657189987717!2d139.736486!3d35.644275!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ba8d7fde9fb%3A0x8732ae3844db17e7!2z44CSMTA4LTAwNzMg5p2x5Lqs6YO95riv5Yy65LiJ55Sw77yV5LiB55uu77yS77yR4oiS77yW!5e0!3m2!1sja!2sjp!4v1651249970339!5m2!1sja!2sjp',
  },
  {
    id: 2,
    location: '大阪拠点',
    zipCode: '〒542-0081',
    address: '大阪府中央区南船場3-7-27',
    src: 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6562.2031331094195!2d135.501684!3d34.677386!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e71a2991e915%3A0x71c54e96462b09fa!2z44CSNTQyLTAwODEg5aSn6Ziq5bqc5aSn6Ziq5biC5Lit5aSu5Yy65Y2X6Ii55aC077yT5LiB55uu77yX4oiS77yS77yX!5e0!3m2!1sja!2sjp!4v1651250135024!5m2!1sja!2sjp',
  },
  {
    id: 3,
    location: '名古屋拠点',
    zipCode: '〒460-0007',
    address: '愛知県名古屋市中区新栄1丁目46-5',
    src: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.754450279253!2d136.91362307502962!3d35.16274417275906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600370c6dd84f53b%3A0x6149a70f0d541f58!2z44CSNDYwLTAwMDcg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5Yy65paw5qCE77yR5LiB55uu77yU77yW4oiS77yVIOODj-OCpOOCpuODg-ODieaghA!5e0!3m2!1sja!2sjp!4v1682428891877!5m2!1sja!2sjp',
  },
]
