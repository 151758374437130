<template>
  <div class="login-wrap">
    <div class="login-wrap-box">
      <div class="login-wrap-title">
        <h1 class="login-wrap-text">ログイン画面</h1>
      </div>
      <ul class="login-form">
        <li class="login-form-list">
          <p class="login-form-title">メールアドレス</p>
          <input v-model="authId" type="text" class="login-form-input" />
        </li>
        <li class="login-form-list">
          <p class="login-form-title">パスワード</p>
          <input v-model="authPass" type="password" class="login-form-input" />
        </li>
      </ul>
      <div class="login-button">
        <button @click="post" class="login-button-box">ログイン</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showPassword: false,
      msg: 'userIDとpasswordを入力して下さい',
      authId: '',
      authPass: '',
    }
  },
  methods: {
    async post() {
      const data = { id: this.authId, pass: this.authPass }
      this.msg = await this.$axios
        .post('/api/v1/login', data) // バックエンド側にPOST
        .then(function (response) {
          return response.data.message
        })
        .catch(function (error) {
          return error.message
        })
      if (this.msg == 'OK') {
        this.$store.dispatch('fetch', this.authId)
        this.$router.push('/postnews')
      } else if (this.msg == '認証エラー') {
        alert('認証失敗')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

.login-wrap {
  background-color: #f5f5f5;
  font-family: 'Noto Sans Japanese', sans-serif;
  &-box {
    background-color: #fafafa;
    margin: auto;
    max-width: 560px;
    padding: 0 10%;
  }
  &-text {
    font-weight: normal;
    letter-spacing: 0.1em;
    padding-top: 100px;
    text-align: center;
  }
}

.login-form {
  list-style: none;
  margin: auto;
  &-input {
    box-sizing: border-box;
    width: 100%;
  }
}

.login-button {
  text-align: center;
  &-box {
    background-color: #ff7300;
    border: none;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #333;
    }
  }
}

@media screen and (min-width: 796px) {
  .login-wrap {
    padding: 200px 15%;
    &-text {
      font-size: 26px;
      padding-top: 75px;
    }
  }
  .login-form {
    padding-top: 50px;
    &-title {
      font-size: 18px;
      margin: 5px 0;
      padding-top: 20px;
    }
  }
  .login-button {
    padding: 75px;
    &-box {
      border-radius: 5px;
      font-size: 23px;
      padding: 13px 10%;
    }
  }
}

@media screen and (max-width: 795px) {
  .login-wrap {
    padding: 100px 15%;
    &-text {
      font-size: 17px;
      padding-top: 40px;
    }
  }
  .login-form {
    padding-top: 30px;
    &-title {
      font-size: 14px;
      margin: 3px 0;
      padding-top: 15px;
    }
  }
  .login-button {
    padding: 40px;
    &-box {
      border-radius: 7px;
      font-size: 10px;
      padding: 7px 5%;
    }
  }
}
</style>
