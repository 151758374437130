<template>
  <div class="about-title">
    <h2 class="about-text">CONTACT</h2>
  </div>
  <div class="all-wrapper">
    <h3 class="about-contact-title">お問い合わせ</h3>
    <div class="contact-wrapper">
      <form
        action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSee6PMvKKnrfqSF-sn38HL3FA9qRuqtIa46j-mFHKm4M2IHAA/formResponse"
        target="hidden_iframe"
        method="POST"
        id="contact-form"
        onsubmit="submitted=true;"
      >
        <div class="flow-step">
          <div class="flow-step-list">
            <p class="step-status" v-for="step in steps" :key="step.id">{{ step.status }}</p>
          </div>
        </div>
        <ul class="for">
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">ご相談内容 :</p>
            </label>
            <div class="consultation" name="entry.1515114129">
              <input type="hidden" name="entry.1515114129" v-model="formData.content" />
              <p class="information">
                {{ formData.content }}
              </p>
            </div>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">お名前 :</p>
              <input type="hidden" name="entry.1828008031" v-model="formData.name" />
              <p class="information-contents">
                {{ formData.name }}
              </p>
            </label>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">ふりがな :</p>
              <input type="hidden" name="entry.840914999" v-model="formData.ruby" />
              <p class="information-contents">
                {{ formData.ruby }}
              </p>
            </label>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">メールアドレス :</p>
              <input type="hidden" name="entry.2007747466" v-model="formData.email" />
              <p class="information-contents">
                {{ formData.email }}
              </p>
            </label>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">電話番号 :</p>
              <input type="hidden" name="entry.455978347" v-model="formData.tel" />
              <p class="information-contents">
                {{ formData.tel }}
              </p>
            </label>
          </li>
          <li class="type-form">
            <label class="item" for="item">
              <p class="information">お問い合せ内容 :</p>
              <input type="hidden" name="entry.992045842" v-model="formData.messages" />
              <p class="information-contents">
                {{ formData.messages }}
              </p>
            </label>
          </li>
          <div class="buttons">
            <button @click="back" class="back_button">戻る</button>
            <input id="contact-form" class="submit_button" key="" name="button" type="submit" value="送信する" />
          </div>
        </ul>
      </form>
      <iframe
        name="hidden_iframe"
        style="display: none"
        id="hidden_iframe"
        onload="if(submitted){window.location='/ContactThanks/';}"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {},
      steps: [
        {
          status: '入力',
        },
        {
          status: '確認',
        },
        {
          status: '完了',
        },
      ],
    }
  },
  created() {
    // localStorageから保存された値を取得する
    const savedData = localStorage.getItem('formData')
    if (savedData) {
      this.formData = JSON.parse(savedData)
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #fff;
  padding: 8% 12%;
}
.page-header {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.page-header + form {
  margin-top: 80px;
}
.flow-step {
  font-family: 'source-han-sans-japanese';
  margin-top: 32px;
  font-size: 20px;
  text-align: -webkit-center;
  .flow-step-list {
    border-bottom: 2px solid #ff7300;
    display: flex;
    justify-content: center;
    width: 300px;
    .step-status {
      color: #b7b7b7;
      font-weight: bold;
      margin: 0 25px 10.5px 0;
    }
    .step-status:nth-child(2) {
      color: #ff6700;
    }
    .step-status:nth-child(2)::after {
      color: #b7b7b7;
    }
    .step-status:nth-child(3) {
      margin-right: 0;
    }
    .step-status::after {
      content: '>';
      margin-left: 17px;
    }
    .step-status:nth-child(3)::after {
      content: '';
    }
  }
}
.for {
  font-family: 'Hiragino Sans';
  padding: 0;
  .type-form {
    display: flex;
    list-style: none;
    margin-top: 141.5px;
    color: #333333;
    align-items: center;
    .item {
      display: flex;
      .information {
        font-weight: bold;
        margin: 0 67px 0 0;
      }
      .information-contents {
        margin: 0 0 0 0;
      }
    }
    .consultation {
      .information {
        margin: 0 0 0 0;
      }
    }
  }
  .type-form:nth-child(2) {
    .item {
      .information {
        margin-right: 99px;
      }
    }
  }
  .type-form:nth-child(3) {
    .item {
      .information {
        margin-right: 83px;
      }
    }
  }
  .type-form:nth-child(4) {
    .item {
      .information {
        margin-right: 35px;
      }
    }
  }
  .type-form:nth-child(5) {
    .item {
      .information {
        margin-right: 84px;
      }
    }
  }
  .type-form:nth-child(6) {
    .item {
      .information {
        white-space: nowrap;
        margin-right: 35px;
      }
    }
  }
  .type-form:nth-child(2),
  .type-form:nth-child(3),
  .type-form:nth-child(4),
  .type-form:nth-child(5),
  .type-form:nth-child(6) {
    margin-top: 49px;
  }
  .buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 103px;
    .submit_button {
      background-color: #ff7300;
      border: 1px solid #ff7300;
      border-radius: 6px;
      color: #fff;
      cursor: pointer;
      font-weight: bold;
      padding: 10px 62px;
    }
    .back_button {
      background-color: #ffffff;
      border: 1px solid #ff7300;
      cursor: pointer;
      font-weight: bold;
      border-radius: 6px;
      color: #ff7300;
      padding: 10px 62px;
    }
    .back_button:hover {
      color: #fff;
      background-color: #ff7300;
    }
    .submit_button:hover {
      background-color: #fff;
      color: #ff7300;
    }
  }
}
@media screen and (max-width: 795px) {
  .contact {
    padding: 0;
  }
  .flow-step {
    margin-top: 49px;
    .flow-step-list {
      width: 248px;
    }
  }
  .for {
    .type-form {
      display: block;
      margin-top: 46.1px;
      .item {
        display: block;
        align-items: flex-end;
        font-size: 13px;
        .information-contents {
          margin: 6px 0 0 0;
        }
      }
      .consultation {
        font-size: 13px;
        .information {
          margin: 6px 0 0 0;
        }
      }
    }
    .type-form:nth-child(2) {
      margin-top: 31px;
    }
    .type-form:nth-child(3),
    .type-form:nth-child(5) {
      margin-top: 21px;
    }
    .type-form:nth-child(4) {
      margin-top: 21px;
    }
    .type-form:nth-child(6) {
      margin-top: 31px;
    }
    .buttons {
      margin-top: 51px;
      display: flex;
      .back_button {
        padding: 10px 50px;
        margin: 0 10px 0 0;
      }
      .submit_button {
        padding: 10px 35px;
      }
    }
  }
}

.wrapper {
  font-family: 'Noto Sans Japanese';
  background-color: #f4f4f4;
  width: 100%;
}

.about-title {
  background-image: url('/src/assets/img/headline.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  margin-top: 100px;
  .about-text {
    color: #fd9602;
    position: absolute;
    font-size: 70px;
    top: 30%;
    left: 10%;
  }
}

.about-contact-title {
  font-size: 25px;
  margin: 5px 0 250px;
  font-weight: normal;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    margin: 7px;
    width: 70px;
    position: initial;
    border-top: solid 5px #ff5900;
  }
}
.all-wrapper {
  font-style: normal;
  font-weight: 300;
  padding: 0 10% 250px;
  background: url('../assets/img/about-bg.jpg');
}

.link {
  align-items: center;
  color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  width: 100%;
}
.contact-wrapper {
  background-color: #fff;
  padding: 77px 13%;
  width: unset;
}

@media screen and (min-width: 795px) {
  .page-header {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .page-header + form {
    margin-top: 80px;
  }
  .icon {
    background-repeat: no-repeat;
    height: 100px;
    margin-right: 30px;
    width: 100px;
  }
}

@media screen and (max-width: 794px) {
  .wrapper {
    padding-bottom: 0;
  }

  .about-title {
    height: 75px;
    margin-top: 70px;
    .about-text {
      font-size: 25px;
    }
  }

  .about-contact-title {
    font-size: 13px;
    margin: 2px 10%;
    font-size: 13px;
    text-align: left;
    &::before {
      content: '';
      width: 40px;
      margin: 2px;
      position: initial;
      border-top: solid 3px #ff5900;
    }
  }
  .all-wrapper {
    padding: 0;
    background: none;
  }
  .contact-wrapper {
    background-color: #fff;
    padding: 29px 45px;
    width: unset;
  }
  .page-header {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
  }
  .page-header + form {
    margin-top: 40px;
  }
  .icon {
    background-repeat: no-repeat;
    height: 35px;
    margin-right: 10px;
    width: 35px;
  }
}
</style>
