<template>
  <div class="wrapper">
    <div class="about-title">
      <h2 class="about-text">ABOUT</h2>
    </div>
    <div class="about-company">
      <h3 class="about-company-title">企業概要</h3>
      <div class="ceo-message" v-for="item in items" :key="item.id">
        <div class="ceo-message-Document">
          <ul class="ceo-message-list">
            <li class="ceo-message-title">{{ item.title }}</li>
            <li class="member-message">{{ item.message }}</li>
            <li class="member-position">{{ item.potion }}</li>
            <li class="member-name">{{ item.name }}</li>
            <li class="member-english-name">{{ item.english }}</li>
          </ul>
          <div class="ceo-message-img"></div>
        </div>
        <p class="member-text" v-html="item.text"></p>
      </div>
    </div>
    <div class="corporate-info">
      <h3 class="company-name">COMPANY</h3>
      <p class="table-name">企業概要</p>
      <table class="all-table">
        <tbody>
          <tr class="table-data" v-for="info in aboutIRup" :key="info.id">
            <td class="table-title">{{ info.item }}</td>
            <td class="table-content" v-html="info.content"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="maps">
      <div class="map-block" v-for="map in maps" :key="map.id">
        <li class="map-title">
          <span>{{ map.location }}</span>
        </li>
        <div class="google-map">
          <iframe
            :src="map.src"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
          <div class="map-address">{{ map.zipCode }} {{ map.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CEO_MESSAGE_INFO, IRUP_CORPORATE_INFO, IRUP_CORPORATE_MAPS } from '@/data.js'
export default {
  name: 'AboutUs',
  data() {
    return {
      items: CEO_MESSAGE_INFO,
      aboutIRup: IRUP_CORPORATE_INFO,
      maps: IRUP_CORPORATE_MAPS,
    }
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  font-family: 'Noto Sans Japanese';
  background-color: #ffffff;
  width: 100%;
}
.about-title {
  background-image: url('/src/assets/img/headline.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  .about-text {
    color: #fd9602;
    position: absolute;
  }
}
.about-company-title {
  margin: 0;
  font-weight: normal;
  position: relative;
}
.about-company-title::before {
  content: '';
  position: absolute;
  display: inline-block;
}
.ceo-message {
  .ceo-message-Document {
    display: flex;
    justify-content: center;
    .ceo-message-img {
      background-image: url('/src/assets/img/about.png');
      background-repeat: no-repeat;
      background-size: contain; /* 画像を縦横比を維持したまま表示 */
      max-width: 100%;
      height: auto;
    }
    .ceo-message-list {
      text-align: left;
      list-style: none;
      .ceo-message-title {
        font-weight: bold;
      }
      .member-message {
        position: relative;
      }
      .member-message::before {
        content: '';
        position: absolute;
        display: inline-block;
      }
      .member-position {
        font-weight: 500;
      }
      .member-name {
        font-weight: 500;
      }
      .member-english-name {
        font-weight: 800;
        color: #c4c4c4;
      }
    }
  }
}
.corporate-info {
  background-image: url('/src/assets/img/SP_Company_gray1.svg');
  background-position: center;
  margin: 0;
  .company-name {
    margin: 0;
    text-align: center;
  }
  .table-name {
    margin: 0;
    font-weight: normal;
    position: relative;
  }
  .table-name::before {
    content: '';
    position: absolute;
    display: inline-block;
  }
  .all-table {
    margin: auto;
    .table-title,
    .table-content {
      text-align: left;
    }
  }
}

.maps {
  .map-title {
    list-style-type: none;
    span {
      color: #000;
    }
  }
  .google-map {
    iframe {
      aspect-ratio: 16/9;
      height: 100%;
      width: 100%;
    }
  }
}
iframe {
  display: block;
}
.map-block + .map-block {
  margin-top: 80px;
}

@media screen and (min-width: 796px) {
  .about-title {
    height: 200px;
    margin-top: 100px;
    .about-text {
      font-size: 70px;
      top: 30%;
      left: 10%;
    }
  }
  .about-company-title {
    font-size: 25px;
    margin: 5px 0 0 10%;
  }
  .about-company-title::before {
    margin: 7px;
    width: 70px;
    position: initial;
    border-top: solid 5px #ff5900;
  }
  .ceo-message {
    margin-top: 170px;
    .ceo-message-Document {
      .ceo-message-img {
        height: 500px;
        width: 535px;
        margin-left: 10%;
      }
      .ceo-message-list {
        .ceo-message-title {
          font-size: 40px;
          margin-top: 80px;
        }
        .member-message {
          font-size: 20px;
          margin-top: 5px;
          padding-left: 53px;
        }
        .member-message::before {
          top: 45%;
          width: 40px;
          left: 3px;
          border-top: solid 5px #fd9602;
        }
        .member-position {
          font-size: 16px;
          margin-top: 50px;
        }
        .member-name {
          font-size: 26px;
          margin-top: 10px;
        }
        .member-english-name {
          font-size: 26px;
        }
      }
    }
    .member-text {
      font-size: 21px;
      margin: 75px 10%;
    }
  }
  .corporate-info {
    padding: 170px 0;
    .company-name {
      font-size: 40px;
    }
    .table-name {
      font-size: 25px;
      text-align: center;
      margin: 5px 30px 0 0;
    }
    .table-name::before {
      width: 40px;
      position: initial;
      margin: 8px;
      border-top: solid 5px #fd9602;
    }
    .all-table {
      border-spacing: 100px 40px;
      padding: 20px 0 10px;
      .table-title,
      .table-content {
        font-size: 21px;
        padding-top: 50px;
      }
    }
  }
  .maps {
    font-size: 20px;
    padding: 50px 0 80px;
    margin: 0 10%;
    .map-title {
      font-size: 40px;
      margin: 20px auto;
      span {
        font-size: 32px;
      }
    }
    .google-map {
      margin-top: 20px;
    }
    .map-address {
      margin-top: 50px;
      padding-bottom: 57px;
      border-bottom: 3px solid #ff5900;
    }
    .map-block:nth-of-type(3) .map-address {
      border-bottom: none;
    }
  }
}

@media screen and (max-width: 795px) {
  .about-title {
    height: 75px;
    margin-top: 70px;
    .about-text {
      font-size: 25px;
      top: 30%;
      left: 10%;
    }
  }
  .about-company-title {
    font-size: 13px;
    margin: 2px 10%;
    font-size: 13px;
    text-align: left;
  }
  .about-company-title::before {
    width: 40px;
    margin: 2px;
    position: initial;
    border-top: solid 3px #ff5900;
  }
  .ceo-message {
    margin-top: 30px;
    .ceo-message-Document {
      .ceo-message-img {
        height: 205px;
        width: 200px;
      }
      .ceo-message-list {
        .ceo-message-title {
          font-size: 15px;
          margin-top: 30px;
        }
        .member-message {
          font-size: 11px;
          margin-top: 5px;
          padding-left: 22px;
        }
        .member-message::before {
          top: 45%;
          width: 20px;
          left: -3px;
          border-top: solid 3px #fd9602;
        }
        .member-position {
          font-size: 7px;
          margin-top: 25px;
        }
        .member-name {
          font-size: 12px;
          margin-top: 5px;
        }
        .member-english-name {
          font-size: 10px;
        }
      }
    }
    .member-text {
      font-size: 12px;
      margin: 30px 10%;
    }
  }
  .corporate-info {
    padding: 100px 0;
    .company-name {
      font-size: 15px;
    }
    .table-name {
      margin-top: 5px;
      font-size: 11px;
      text-align: center;
    }
    .table-name::before {
      top: 50%;
      position: initial;
      width: 20px;
      left: -4%;
      border-top: solid 3px #fd9602;
      margin: 2px;
    }
    .all-table {
      border-spacing: 64px 40px;
      padding: 20px 0 10px;
      .table-title,
      .table-content {
        font-size: 12px;
        padding-top: 36px;
      }
    }
  }
  .maps {
    padding: 50px 0 80px;
    .map-title {
      font-size: 14px;
      margin-left: 30px;
      span {
        font-size: 14px;
      }
    }
    .google-map {
      display: flex;
      flex-flow: column;
      padding-bottom: 57px;
      border-bottom: 1px solid #ff5900;
    }
    .map-block:nth-of-type(3) .google-map {
      border-bottom: none;
    }
    .map-address {
      order: 1;
      font-size: 12px;
      margin: 10px 0 10px 30px;
    }
    iframe {
      order: 2;
    }
  }
}
</style>
