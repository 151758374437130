import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import http from './axios'
import '@/assets/css/normalize.css'
import '@/assets/font/typekit.js'

const app = createApp(App).use(router)

app.config.globalProperties.$axios = http

app.mount('#app')
