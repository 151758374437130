<script setup>
/** ページ上部、ヘッダーの直下に表示されるページタイトルコンポーネント */
import Graph from '@/components/pages/recruit/PieChart.vue'
import NextArrow from '@/components/pages/recruit/NextArrow.vue'
import AverageAge from '@/components/pages/recruit/AverageAge.vue'
import InterviewVideos from '@/components/pages/recruit/InterviewVideos.vue'
import Outline from '@/components/pages/recruit/OutlineComponent.vue'
/** テキストやプロパティに関しては可読性・保守性を考慮し変数に格納する */
/** ページ概要 */
const overviews = [
  'iRup株式会社では、共に成長し、新しい価値を創造する仲間を募集しています。私たちのミッションは、お客様のビジネスを成功に導くことです。そのためには、熱意を持ち、挑戦を恐れない仲間が不可欠です。',
  '当社の強みは、最先端の技術と高い専門性を持つチームが、様々なプロジェクトに取り組むことです。また、社員一人ひとりが成長し続ける環境を提供し、能力開発をサポートしています。',
  '採用においては、経験やスキルだけでなく、チャレンジ精神や向上心、チームで協力して働く意欲を重視しています。私たちは、中途採用を通じて、幅広い人材を迎え入れています。',
  'あなたの力を発揮し、未来を創造しませんか？詳細な募集要項は、以下のページでご確認いただけます。私たちと一緒に、新しい価値を生み出す仕事に挑戦しましょう。',
]

/** 円グラフのプロパティ */
const graph = {
  location: {
    title: ['拠点別人員の', '割合'],
    url: require('@/assets/img/chart_01.svg'),
    items: [
      { name: '東京42%', color: '#ff9300' },
      { name: '大阪54%', color: '#ff5b00' },
      { name: '名古屋4%', color: '#ffd50d' },
    ],
    paragraphs: [
      '全国に拠点を展開し、多様なプロジェクトに取り組んでいます。拠点別の人数比は、大阪が54%、東京が42%、名古屋が4%となっており、各地で専門性の高い人材が活躍しています。これにより、地域ごとのビジネスニーズに迅速かつ柔軟に対応することが可能です。',
      '東京を中心に多くのプロジェクトが展開されており、大阪や名古屋でも成長を続けています。各拠点での連携も密に行われ、知識や経験の共有を通じて、更なるスキルアップを目指しています。また、地域密着型のサービス提供を行い、お客様との距離を縮めることで、信頼関係を築いています。',
    ],
  },
  gender: {
    title: ['男女比の', '割合'],
    url: require('@/assets/img/chart_02.svg'),
    items: [
      { name: '男性63%', color: '#ff9300' },
      { name: '女性37%', color: '#ffd50d' },
    ],
    paragraphs: [
      '現在、男性が63%、女性が37%となっており、女性比率は業界内の平均を上回っています。当社は、多様な人材が活躍できる環境を大切にし、異なる視点やアイデアを尊重しています。',
      'この高い女性比率は、弊社の柔軟な働き方やサポート体制が、多様な性別や背景を持つ人材に対して魅力的であることを示しています。当社では、男女問わず、チームで協力し合いながら働くことを重視し、全ての社員が自分らしく活躍できる場を提供しています。',
      '私たちは、男女の多様性を活かし、より創造的で革新的なサービスを展開していくことを目指しています。iRupで、あなたも自分のスキルや経験を活かして、共に成長していきましょう。',
    ],
  },
}
const projects = [
  'AWSを利用したクラウドシステムの機能追加案件',
  '企業向けバックオフィス支援システムの開発',
  '案件先テキスト',
]
const interviewVideoUrls = [
  require('@/assets/img/recruit_03.jpg'),
  require('@/assets/img/recruit_03.jpg'),
  require('@/assets/img/recruit_03.jpg'),
  require('@/assets/img/recruit_03.jpg'),
]
/**  */
/**  */
</script>
<template>
  <!-- ヘッダーがfixedのため重ならないようスペースを追加 -->
  <div class="header-space"></div>
  <div class="wrapper">
    <div class="about-title">
      <h2 class="about-text">RECRUIT</h2>
    </div>
    <article class="content-wrapper">
      <h3 class="about-recruit-title">採用情報</h3>
      <section class="overviews">
        <template v-for="overview in overviews" :key="overview">
          <p class="overview" v-if="overview.length">{{ overview }}</p>
        </template>
      </section>
      <!-- ビデオ -->
      <Graph
        class="graph"
        :url="graph.location.url"
        :title="graph.location.title"
        :items="graph.location.items"
        :paragraphs="graph.location.paragraphs"
      />
      <NextArrow class="next-arrow" />
      <Graph
        class="graph"
        :url="graph.gender.url"
        :title="graph.gender.title"
        :items="graph.gender.items"
        :paragraphs="graph.gender.paragraphs"
      />
      <NextArrow class="next-arrow" />
      <AverageAge class="average-age" />
      <ProjectBox :projectList="projects" />
      <InterviewVideos :urls="interviewVideoUrls" class="interview" />
      <Outline class="outline" />
    </article>
  </div>
</template>
<style lang="scss" scoped>
/** ブレイクポイントの設定はassetsから読み込む */
@use '@/assets/sass/foundation/breakpoint' as bp;
@use '@/assets/sass/foundation/font' as font;
@mixin content-width {
  @include bp.mq(pc) {
    margin: 0 auto;
    max-width: 1080px;
  }
}

.content {
  &-wrapper {
    background-color: #f4f4f4;
    padding: 0 10% 100px;
    @include bp.mq(pc) {
      padding-bottom: 200px;
    }
  }
}
.overview {
  font-size: 12px;
  line-height: 1.5;
  margin-top: 25px;
  @include content-width;
  @include font.genno(n);
  @include bp.mq(pc) {
    font-size: 16px;
    line-height: 2;
    margin-top: 60px;
    @include font.genno(r);
  }
  & + & {
    margin-top: 20px;
  }
}

.graph {
  margin-top: 50px;
  @include content-width;
  @include bp.mq(pc) {
    margin-top: 80px;
  }
}

.next-arrow {
  margin-top: 50px;
  @include content-width;
  @include bp.mq(pc) {
    margin-top: 80px;
  }
}

.average-age {
  margin-top: 50px;
  @include content-width;
  @include bp.mq(pc) {
    margin-top: 140px;
  }
}

.interview {
  margin: 100px -30px 0;
  @include bp.mq(pc) {
    margin-top: 200px;
  }
}

.outline {
  margin-top: 70px;
  @include content-width;
  @include bp.mq(pc) {
    margin-top: 120px;
  }
}

.about-recruit-title {
  font-size: 13px;
  margin-top: 2px;
  font-size: 13px;
  text-align: left;
  font-weight: normal;
  position: relative;
  @include bp.mq(pc) {
    font-size: 25px;
    margin-top: 5px;
  }
  &::before {
    content: '';
    display: inline-block;
    width: 40px;
    margin: 2px;
    position: initial;
    border-top: solid 3px #ff5900;
    @include bp.mq(pc) {
      margin: 7px;
      width: 70px;
      border-top: solid 5px #ff5900;
    }
  }
}

.wrapper {
  width: 100%;
}
</style>
