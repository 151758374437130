<template>
  <div class="wrapper">
    <div class="about-title">
      <h2 class="about-text">SERVICE</h2>
    </div>
    <div class="about-business">
      <h3 class="about-business-title">事業内容</h3>
      <div class="service-container">
        <div class="service-box">
          <div class="service-box-inner" v-for="service in services" :key="service.id">
            <p class="service-number">
              {{ service.number }} <span class="service-title">{{ service.title }}</span>
            </p>
            <div class="service-img-wrapper">
              <img class="service-img" :src="service.image" />
            </div>
            <div class="service-text">{{ service.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      services: [
        {
          id: '1',
          number: '01',
          title: ' - 受託開発',
          text: 'お客様の要件定義から設計・開発・テスト・保守まで、一貫したソフトウェア開発サービスを提供しています。品質とスケジュールに厳格にこだわり、最適な開発プロセスを導入することで、効率的かつコストパフォーマンスの高いソリューションを実現します。ウェブアプリケーション、モバイルアプリ、AI・IoT技術を活用したプロジェクトなど、多岐にわたる開発実績がございます。お客様と密なコミュニケーションを図り、期待以上の成果をお届けいたします。',
          image: require('../assets/img/service_01.png'),
        },
        {
          id: '2',
          number: '02',
          title: ' - テックソリューション',
          text: '弊社のSES事業では、お客様のニーズに合わせたシステムエンジニアリングサービスを提供しております。高度な技術力を持つエンジニアが最適なソリューションを実現し、お客様のビジネス成長をサポートします。インフラ構築、アプリケーション開発、システム運用・保守など、幅広い分野でのサービス展開を行っております。長年の経験と実績により、お客様のあらゆる課題に対応できる自信があります。適切な技術と人材を最大限活用し、お客様の期待を超える価値を創出いたします。',
          image: require('../assets/img/service_02.png'),
        },
        {
          id: '3',
          number: '03',
          title: ' - 教育事業',
          text: '弊社の教育事業では、IT技術者の育成・スキルアップをサポートする研修プログラムを展開しています。最新の技術トレンドに対応したカリキュラムで、未経験者から経験豊富な技術者まで、幅広い層に対応した研修を提供しています。人材の能力開発に貢献し、お客様企業の競争力向上を支援します。プログラミングスキルやシステム設計力はもちろん、コミュニケーション力やプロジェクトマネジメント能力など、総合的な人材育成を目指しています。',
          image: require('../assets/img/service_03.png'),
        },
        {
          id: '4',
          number: '04',
          title: ' - 自社開発',
          text: '自社開発事業では、独自のアイデアと技術を活かしたソフトウェア製品・サービスの開発を行っています。市場のニーズを先取りし、革新的なソリューションを提案。お客様のビジネスを次のレベルへと導く、付加価値の高い製品・サービスを提供しております。クラウドベースのビジネスアプリケーション、AIを活用したデータ分析ツール、IoTデバイス向けのカスタムソフトウェアなど、幅広い分野で独創的な製品・サービスを開発し、お客様の課題解決に取り組んでいます。私たちの自社開発事業は、技術力の高さと創造力が融合した、まさにiRupの魅力を最大限に発揮する事業です。',
          image: require('../assets/img/service_04.png'),
        },
      ],
    }
  },
}
</script>
<styles coped lang="scss">
$sp1: 458px;
$sp2: 796px;
$pc1: 1200px;
$pc2: 960px;

@mixin sp1 {
  @media screen and (max-width: ($sp1)) {
    @content;
  }
}
@mixin sp2 {
  @media screen and (max-width: ($sp2)) {
    @content;
  }
}
@mixin pc1 {
  @media screen and (max-width: ($pc1)) {
    @content;
  }
}
@mixin pc2 {
  @media screen and (max-width: ($pc2)) {
    @content;
  }
}

.wrapper {
  font-family: 'Noto Sans Japanese';
  background-color: #f4f4f4;
  width: 100%;
  @include sp2 {
    padding-bottom: 20%;
  }
}

.about-title {
  background-image: url('/src/assets/img/headline.jpg');
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 200px;
  margin-top: 100px;
  @include sp2 {
    height: 75px;
    margin-top: 70px;
  }
  .about-text {
    color: #fd9602;
    position: absolute;
    font-size: 70px;
    top: 30%;
    left: 10%;
    @include sp2 {
      font-size: 25px;
    }
  }
}

.about-business-title {
  font-size: 25px;
  margin: 5px 0 0 10%;
  font-weight: normal;
  position: relative;
  @include sp2 {
    font-size: 13px;
    margin: 2px 10% 96px 10%;
    font-size: 13px;
    text-align: left;
  }
  &::before {
    content: '';
    position: absolute;
    display: inline-block;
    margin: 7px;
    width: 70px;
    position: initial;
    border-top: solid 5px #ff5900;
    @include sp2 {
      width: 40px;
      margin: 2px;
      position: initial;
      border-top: solid 3px #ff5900;
    }
  }
}

.service-container {
  padding: 185px 10%;
  @include sp2 {
    padding: 0;
  }
}

.service-box {
  text-align: right;
  .image-photo {
    width: 45%;
  }
}

.service-box-inner {
  background-color: #fff;
  margin: 0;
  padding: 10% 7%;
  &:nth-child(n + 2) {
    margin-top: 260px;
    @include sp2 {
      margin-top: 95px;
    }
  }
}

.service-number {
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  margin: 0;
  @include sp2 {
    font-size: 20px;
  }
  .service-title {
    font-size: 25px;
    @include sp2 {
      font-size: 15px;
    }
  }
}
.service-number::after {
  display: block;
  content: '';
  width: 350px;
  border-bottom: #ff8700 5px solid;
  @include sp2 {
    width: 200px;
  }
}

.service-text {
  text-align: left;
  margin-top: 20%;
  font-size: 21px;
  @include sp2 {
    font-size: 12px;
    margin-top: 20%;
  }
}
.service-img-wrapper {
  height: 0;
  @include sp2 {
    height: 170px;
  }
}
.service-img {
  position: relative;
  bottom: 300px;
  width: 50%;
  @include pc1 {
    bottom: 180px;
    width: 45%;
  }
  @include pc2 {
    bottom: 152px;
    width: 38%;
  }
  @include sp2 {
    bottom: -20px;
    width: 50%;
  }
  @include sp1 {
    bottom: -28px;
    width: 71%;
  }
}
</styles>
