<template>
  <main class="main">
    <div class="control-event-wrapper">
      <div class="wrapper" id="app">
        <nav class="nav">
          <ul class="nav-list">
            <router-link to="/postnews" class="nav-item-btn">ニュース</router-link>
            <router-link to="/controlEvent" class="nav-item-btn">イベント</router-link>
            <router-link to="/postemployee" class="nav-item-btn">社員ページ</router-link>
          </ul>
        </nav>
        <section class="event-wrapper">
          <div class="event-title">
            <p class="title">タイトル</p>
            <input type="text" name="title" v-model="titleInput" class="event-title-input" />
          </div>
          <div class="event-date">
            <p class="date">日付</p>
            <input class="event-date-check" type="date" name="date" v-model="dateInput" />
          </div>
          <div class="event-imageURL-field">
            <p class="file">画像URL</p>
            <input type="text" class="event-imageURL-input" v-model="file_URL" placeholder="画像ファイルパス" />
          </div>
          <div class="flex-container">
            <div class="event-category">
              <p class="event-category-text">カテゴリーID</p>
              <input class="event-category-input" type="number" name="category_id" v-model="categoryId" min="0" />
            </div>
            <div class="toggle-button">
              <p class="button-text">表示/非表示</p>
              <div class="toggle-btn">
                <label for="switch">
                  <input type="checkbox" name="toggle" id="switch" v-on:click="toggleSwitch" />
                  <div class="toggle-btn-base"></div>
                  <div class="toggle-btn-circle"></div>
                </label>
              </div>
            </div>
          </div>
          <div class="event-message">
            <p class="message">本文入力</p>
            <textarea class="event-message-textarea" v-model="textInput" name="text"></textarea>
          </div>
          <a href="#overlay" class="post">
            <button class="post-button" v-on:click="openOverlay">投稿する</button>
          </a>
        </section>
        <h2 class="past-article">過去記事</h2>
        <section class="article-wrapper">
          <div class="article-container" v-for="content in contents" :key="content.id">
            <div class="article-title">
              <p class="title">{{ content.title }}</p>
              <p class="date">{{ content.date }}</p>
              <p class="article-display" v-if="content.display === 0">非表示</p>
            </div>
            <div class="button-container">
              <button
                class="button"
                name="edit"
                v-on:click="
                  openEditModal(
                    content.id,
                    content.date,
                    content.file_name,
                    content.title,
                    content.content,
                    content.category_id,
                    content.display
                  )
                "
              >
                編集
              </button>
              <button class="button" v-on:click="deleteDisplay(content.id, content.title)">削除</button>
            </div>
          </div>
        </section>
      </div>
      <div id="overlay" v-show="overlayDisplay">
        <div class="event-confirm-wrap" v-show="confirmDisplay">
          <div class="event-confirm">
            <section class="event-confirm-alert-wrap">
              <p class="event-confirm-alert">入力内容の確認をしてください。</p>
            </section>
            <section class="event-confirm-content-wrap">
              <div class="event-confirm-content">
                <div class="event-confirm-display" v-if="this.displayValue === 0">非表示</div>
                <div class="event-confirm-heading">タイトル</div>
                <p class="event-confirm-text">{{ titleInput }}</p>
                <div class="event-confirm-heading">日付</div>
                <p class="event-confirm-date">{{ dateInput }}</p>
                <div class="event-confirm-heading">画像URL</div>
                <p class="event-confirm-url">{{ file_URL }}</p>
                <div class="event-confirm-heading">カテゴリーID</div>
                <p class="event-confirm-category">{{ categoryId }}</p>
                <div class="event-confirm-heading">本文入力</div>
                <p class="event-confirm-textarea">{{ textInput }}</p>
              </div>
            </section>
            <div class="event-confirm-btn-wrap">
              <button type="button" class="back-form-button" v-on:click="backForm">戻る</button>
              <a href="#overlay" class="jump-click">
                <button
                  class="event-confilm-post-button"
                  v-on:click="postConfirm(titleInput, dateInput, file_URL, categoryId, displayValue, textInput)"
                >
                  投稿する
                </button>
              </a>
            </div>
          </div>
        </div>
        <div class="event-completion-wrap" v-show="complitedDisplay">
          <div class="event-completion">
            <p class="completion-message">投稿完了しました。</p>
            <router-link to="/controlevent" class="jump-click">
              <button class="close-button" type="button" v-on:click="closeAll" onclick="rset(this.form)">戻る</button>
            </router-link>
          </div>
        </div>
        <div class="edit-modal-wrap" v-show="editModalDisplay">
          <div class="edit-modal">
            <section class="edit-modal-content-wrap">
              <div class="edit-modal-content">
                <div class="edit-title-field">
                  <p class="edit-heading">タイトル</p>
                  <input
                    type="text"
                    name="title"
                    class="edit-title-input"
                    placeholder="タイトル"
                    v-model="updateTitle"
                  />
                </div>
                <div class="edit-date-field">
                  <p class="edit-heading">日付</p>
                  <input type="date" class="edit-date-check" name="date" placeholder="日付" v-model="updateDate" />
                </div>
                <div class="edit-file">
                  <p class="edit-file-text">画像URL</p>
                  <input
                    type="text"
                    class="employee-imageURL-input"
                    v-model="updateFile_URL"
                    placeholder="画像ファイルパス"
                  />
                </div>
                <div class="edit-flex">
                  <div class="category_id">
                    <p class="edit-heading-category">カテゴリーID</p>
                    <div class="edit-category-wrapper">
                      <label for="edit-switch">
                        <input
                          type="number"
                          class="edit-category-input"
                          name="カテゴリーID"
                          v-model="updateCategoryId"
                        />
                      </label>
                    </div>
                  </div>
                  <div class="edit-display-switch">
                    <p class="edit-heading">表示/非表示</p>
                    <div class="edit-toggle-btn">
                      <label for="edit-switch">
                        <input
                          type="checkbox"
                          name="toggle"
                          id="edit-switch"
                          v-on:click="toggleSwitch"
                          v-model="updateChecked"
                        />
                        <div class="edit-toggle-btn-base"></div>
                        <div class="edit-toggle-btn-circle"></div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="edit-textarea-field">
                  <p class="edit-heading">本文入力</p>
                  <textarea
                    class="edit-textarea-input"
                    name="text"
                    placeholder="本文入力"
                    v-model="updateContent"
                    required
                  />
                </div>
              </div>
              <div class="edit-modal-btn-wrap">
                <button type="button" class="edit-modal-back-btn" v-on:click="backEditModal">戻る</button>
                <button type="button" class="edit-modal-btn" v-on:click="editDisplay">編集完了</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      overlayDisplay: false,
      confirmDisplay: false,
      complitedDisplay: false,
      editModalDisplay: false,
      titleInput: '',
      dateInput: '',
      file_URL: '',
      categoryId: '',
      displayValue: 0,
      displayText: '',
      confirmToggleDisplay: false,
      textInput: '',
      contents: [],
      targetId: '',
      updateDate: '',
      updateFile_URL: '',
      updateTitle: '',
      updateContent: '',
      updateCategoryId: '',
      updateDisplay: '',
      updateChecked: '',
    }
  },
  methods: {
    openOverlay: function () {
      if (!this.titleInput) {
        alert('タイトルを入力してください。')
      } else if (!this.dateInput) {
        alert('日付を入力してください。')
      } else if (!this.file_URL) {
        alert('画像URLを入力してください。')
      } else if (this.categoryId === '') {
        alert('カテゴリーIDを入力してください。')
      } else if (!this.textInput) {
        alert('本文を入力してください。')
      } else {
        this.overlayDisplay = true
        this.confirmDisplay = true
        if (this.displayValue == 0) {
          this.displayText = '非表示'
        } else {
          this.displayText = '表示'
        }
      }
    },
    toggleSwitch: function () {
      if (this.confirmToggleDisplay == true) {
        this.displayValue = 0
      } else {
        this.displayValue = 1
      }
      if (this.confirmToggleDisplay == true) {
        this.confirmToggleDisplay = false
      } else {
        this.confirmToggleDisplay = true
      }
    },
    backForm: function () {
      this.overlayDisplay = false
      this.confirmDisplay = false
    },
    postConfirm: function () {
      this.$axios
        .post('/api/v1/events/post', {
          title: this.titleInput,
          date: this.dateInput,
          file_name: this.file_URL,
          category_id: this.categoryId,
          display: this.displayValue,
          content: this.textInput,
        })
        .then((res) => {
          this.posts = res.data.posts
          this.complitedDisplay = true
          this.confirmDisplay = false
        })
        .catch((err) => {
          alert('投稿に失敗しました。\n入力内容をご確認下さい。')
          console.log(err)
        })
    },
    editDisplay: function () {
      if (!this.updateTitle) {
        alert('タイトルを入力してください。')
      } else if (!this.updateDate) {
        alert('日付を入力してください。')
      } else if (!this.updateFile_URL) {
        alert('画像URLを入力してください。')
      } else if (this.updateCategoryId === '') {
        alert('カテゴリーIDを入力してください。')
      } else if (!this.updateContent) {
        alert('本文を入力してください。')
      } else {
        if (this.updateChecked == true) {
          this.updateDisplay = 1
        } else {
          this.updateDisplay = 0
        }
        this.$axios
          .post('api/v1/events/update', {
            id: this.targetId,
            date: this.updateDate,
            url: this.updateFile_URL,
            title: this.updateTitle,
            content: this.updateContent,
            categoryId: this.updateCategoryId,
            display: this.updateDisplay,
          })
          .then((res) => {
            this.posts = res.data.posts
            this.complitedDisplay = true
            this.editModalDisplay = false
            window.scroll({ top: 0, behavior: 'smooth' })
          })
          .catch((err) => {
            alert('編集に失敗しました。')
            console.log('err:', err)
          })
      }
    },
    openEditModal: function (id, date, file_name, title, content, category_id, display) {
      window.scroll({ top: 0, behavior: 'smooth' })
      this.targetId = id
      this.updateDate = date.split('.').join('-')
      this.updateFile_URL = file_name
      this.updateTitle = title
      this.updateContent = content
      this.updateCategoryId = category_id
      this.updateDisplay = display
      if (this.updateDisplay == 1) {
        this.updateChecked = true
      } else {
        this.updateChecked = false
      }
      this.overlayDisplay = true
      this.editModalDisplay = true
    },
    deleteDisplay: function (id, title) {
      this.targetId = id
      const result = window.confirm(title + 'のイベントを削除しますか？')
      if (result == true) {
        this.$axios
          .post('api/v1/events/delete', {
            id: this.targetId,
          })
          .then(() => {
            alert(title + 'のイベントを削除しました。')
            this.$router.go({ path: this.$router.currentRoute.path, force: true })
          })
          .catch((err) => {
            alert('削除に失敗しました。')
            console.log('err:', err)
          })
      }
    },
    backEditModal: function () {
      this.overlayDisplay = false
      this.editModalDisplay = false
    },
    closeAll: function () {
      window.location.reload()
      this.overlayDisplay = false
      this.complitedDisplay = false
    },
  },
  async created() {
    await this.$axios
      .get('api/v1/events/list')
      .then((response) => {
        this.contents = response.data
      })
      .catch((err) => {
        console.log('err:', err)
      })
  },
}
</script>

<style lang="scss" scoped>
.control-event-wrapper {
  background-color: #f5f5f5;
  position: relative;
}

.wrapper {
  margin: 0 auto;
  max-width: 1080px;
  padding: 220px 10% 200px;
  & p {
    font-family: 'Hiragino Kaku Gothic ProN';
    font-size: 15px;
  }
}

.nav {
  margin-bottom: 50px;
  &-list {
    display: flex;
    list-style: none;
    margin: 0 auto;
    padding: 0;
  }
}

.nav-item-wrap:nth-of-type(n + 2) {
  margin-left: 45px;
}

.nav-item-btn {
  background-color: #fd9603;
  border-radius: 6px;
  color: #fff;
  cursor: default;
  display: block;
  font-size: 23px;
  font-size: 23px;
  height: 23px;
  padding: 13px 0;
  text-align: center;
  text-decoration: none;
  text-decoration: none;
  width: 192px;
  width: 192px;
}

.nav-item-btn:hover {
  background-color: #c0c0c0;
}

.nav-item-btn:nth-of-type(2n + 2) {
  background-color: #c0c0c0;
}

.nav-item-btn:nth-of-type(n + 2) {
  margin-left: 50px;
}

.event-wrapper {
  background-color: #fafafa;
  margin-bottom: 150px;
  max-width: 1080px;
  padding: 70px 92px 300px 62px;
}

.event-title {
  max-width: 540px;
}

.event-title-input {
  background-color: #fff;
  border: solid 1px #707070;
  height: 30px;
  margin: 10px 0 30px;
  width: 100%;
}

.flex-container {
  display: flex;
  margin: 30px 0;
}

.event-date {
  margin-right: 40px;
  position: relative;
  &-check {
    margin: 10px 0 20px;
  }
}

input[type='date']::-webkit-calendar-picker-indicator {
  background: transparent;
  z-index: 2;
}

input[type='date']::after {
  border-color: #000 transparent;
  border-style: solid;
  border-width: 12px 7px 0;
  content: '';
  height: 0;
  left: 175px;
  position: absolute;
  width: 0;
}

.event-date-check {
  border: solid 1px #707070;
  height: 30px;
  padding: 0 10px;
  width: 180px;
}

.file {
  margin: 0;
}

.event-imageURL-field {
  margin-top: 25px;
  max-width: 540px;
  padding: 0;
}

.event-imageURL-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.event-imageURL-input::placeholder {
  color: transparent;
}

.toggle-button {
  position: relative;
}

.button-text {
  margin-bottom: 25px;
}

.toggle-input {
  cursor: pointer;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.toggle-label {
  background: #fd9603;
  border-radius: 40px;
  box-sizing: border-box;
  display: inline-block;
  height: 30px;
  position: relative;
  transition: 0.4s;
  width: 80px;
}

.toggle-label::after {
  background: #fff;
  border-radius: 100%;
  content: '';
  height: 24px;
  left: 3px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 24px;
  z-index: 2;
}

.toggle-input:checked + .toggle-label {
  background-color: #e6e6e6;
}

.toggle-input:checked + .toggle-label::after {
  background: #fff;
  left: 53px;
}

.event-message {
  height: 944px;
  max-width: 926px;
}

.event-message-textarea {
  height: 100%;
  margin: 10px 0 20px;
  resize: none;
  width: 100%;
}

.post {
  float: right;
  margin: 100px 0 0 auto;
  &-button {
    background-color: #ff7300;
    border: none;
    border-radius: 6px;
    color: #fff;
    font-size: 23px;
    height: 49px;
    text-align: center;
    width: 192px;
    &:hover {
      background-color: #333;
      cursor: pointer;
    }
  }
}

.past-article {
  font-size: 30px;
  font-weight: normal;
}

.article-wrapper {
  background-color: #fafafa;
  max-width: 1080px;
  padding: 40px 62px;
}

.article-container {
  align-items: center;
  border-bottom: 1px solid #333;
  display: flex;
  justify-content: space-between;
}

.article-title {
  align-items: center;
  background-color: transparent;
  display: flex;
  padding: 20px 10px;
  pointer-events: none;
  word-wrap: break-word;
}

.article-title p + p {
  margin-left: 20px;
}

.article-display {
  background-color: #fff;
  border: solid 1px #ef9b38;
  border-radius: 6px;
  color: #ef9b38;
  font-size: 15px;
  height: fit-content;
  padding: 5px 0;
  text-align: center;
  width: 96px;
}

.button-container {
  margin-right: 30px;
  padding: 20px 10px;
}

.button {
  background-color: #ff7300;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 20px;
  height: 43px;
  width: 108px;
  &:hover {
    background-color: #333;
    cursor: pointer;
  }
}

.button + .button {
  margin-left: 10px;
}

#overlay {
  background-color: rgb(0 0 0 / 50%);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 5;
}

.jump-click {
  text-decoration: none;
}

.event-confirm-wrap {
  height: 100%;
  margin-top: 440px;
  padding: 0 50px;
  width: 100%;
}

.event-confirm {
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  max-height: 1372px;
  max-width: 1009px;
  padding: 63px 71px 100px;
}

.event-confirm-alert-wrap {
  border-bottom: 1px solid #707070;
}

.event-confirm-alert {
  font-size: 25px;
  margin: 0 0 40px;
}

.event-confirm-display {
  background-color: #fff;
  border: 1px solid #fd9603;
  border-radius: 5px;
  color: #fd9603;
  font-size: 14px;
  font-weight: 100;
  margin-top: 50px;
  padding: 5px 0;
  text-align: center;
  width: 80px;
}

.event-confirm-heading {
  margin-top: 50px;
}

.event-confilm-text {
  font-size: 15px;
  margin-top: 20px;
}

.event-confilm-date {
  font-size: 15px;
  margin-top: 20px;
}

.event-confirm-attachment {
  align-items: center;
  display: flex;
  height: 324px;
  justify-content: center;
  margin-top: 40px;
  width: 532px;
}

.event-confirm-textarea {
  margin-top: 20px;
  white-space: pre-wrap;
}

.event-confirm-btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.back-form-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  padding: 10px 0;
  width: 192px;
}

.back-form-button:hover {
  background-color: #707070;
}

.event-confilm-post-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  margin-left: 50px;
  padding: 10px 0;
  width: 192px;
}

.event-confilm-post-button:hover {
  background-color: #707070;
}

.event-completion-wrap {
  background-color: #fff;
  border-radius: 20px;
  height: 497px;
  margin-top: 450px;
  padding: 0;
  width: 745px;
  z-index: 11;
}

.event-completion {
  align-items: center;
  justify-content: center;
  margin: 185px auto;
  width: 300px;
}

.completion-message {
  font-size: 27px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  text-align: center;
}

.close-button {
  background-color: #ff6e00;
  border: none;
  border-radius: 6px;
  color: #fff;
  display: block;
  font-size: 23px;
  margin: 50px;
  padding: 10px 0;
  width: 192px;
}

.close-button:hover {
  background-color: #707070;
}

.edit-modal-wrap {
  height: 100%;
  margin-top: 440px;
  padding: 0 50px;
  width: 100%;
}

.edit-modal {
  background-color: #fff;
  border-radius: 20px;
  margin: 0 auto;
  max-height: 1372px;
  max-width: 1009px;
  padding: 63px 71px 170px;
}

.edit-title-field {
  margin: 0;
  max-width: 540px;
  padding: 0;
}

.edit-heading {
  font-size: 15px;
  width: 90px;
}

.edit-title-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 33px;
  margin-top: 15px;
  padding: 0;
  width: 100%;
}

.edit-title-input::placeholder {
  color: transparent;
}

.edit-flex {
  display: flex;
  margin-top: 40px;
}

.edit-date-field {
  position: relative;
}

.edit-date-check {
  border: solid 1px #707070;
  height: 30px;
  padding: 0 10px;
  width: 180px;
}

.edit-date-check::-webkit-calendar-picker-indicator {
  background: transparent;
  z-index: 2;
}

.edit-date-check::after {
  border-color: #000 transparent transparent;
  border-style: solid;
  border-width: 12px 7.5px 0;
  content: '';
  height: 0;
  position: absolute;
  right: 10px;
  width: 0;
}

.edit-display-switch {
  position: relative;
  width: 200px;
}

.edit-toggle-btn {
  // margin-top: 30px;
  position: relative;
}

.edit-toggle-btn-base {
  background-color: #c3c3c3;
  border-radius: 30px;
  height: 33px;
  transition: 0.5s;
  width: 100px;
}

input[type='checkbox'] {
  position: absolute;
}

#edit-switch {
  display: none;
}

.edit-toggle-btn-circle {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  height: 27px;
  left: 68px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 27px;
}

input:checked ~ .edit-toggle-btn-base {
  background-color: #fab805;
  transition: 0.5s;
}

input:checked ~ .edit-toggle-btn-circle {
  translate: -62px;
}

.edit-textarea-field {
  height: 700px;
  margin-top: 40px;
  max-width: 926px;
  padding: 0;
}

.edit-textarea-input {
  border: 1px solid #707070;
  box-shadow: 0 0 2px rgb(0 0 0 / 20%);
  font-size: 20px;
  height: 100%;
  margin-top: 15px;
  padding: 0;
  resize: none;
  width: 100%;
}

.edit-textarea-input::placeholder {
  color: transparent;
}

.edit-modal-btn-wrap {
  display: flex;
  justify-content: flex-end;
  margin-top: 100px;
}

.edit-modal-link {
  text-decoration-line: none;
}

.edit-modal-back-btn {
  align-items: center;
  background-color: #ff7300;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 23px;
  font-weight: 500;
  height: 45px;
  justify-content: center;
  width: 185px;
}

.edit-modal-btn {
  align-items: center;
  background-color: #ff7300;
  border: none;
  border-radius: 5px;
  color: #fff;
  display: flex;
  font-size: 23px;
  font-weight: 500;
  height: 45px;
  justify-content: center;
  margin-left: 50px;
  width: 185px;
}

.edit-toggle-btn-base {
  background-color: #c3c3c3;
  border-radius: 30px;
  height: 33px;
  transition: 0.5s;
  width: 100px;
}

input:checked ~ .edit-toggle-btn-base {
  background-color: #fab805;
  transition: 0.5s;
}

.edit-toggle-btn-circle {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  height: 27px;
  left: 68px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 27px;
}

input:checked ~ .edit-toggle-btn-circle {
  translate: -62px;
}

#edit-switch {
  display: none;
}

#switch {
  display: none;
}

.toggle-btn {
  position: relative;
}

.toggle-btn-base {
  background-color: #c3c3c3;
  border-radius: 30px;
  height: 33px;
  transition: 0.5s;
  width: 100px;
}

.toggle-btn-circle {
  background-color: #fff;
  border-radius: 100%;
  box-shadow: 0 2px 10px rgb(0 0 0 / 30%);
  height: 27px;
  left: 68px;
  position: absolute;
  top: 3px;
  transition: 0.5s;
  width: 27px;
}

input:checked ~ .toggle-btn-base {
  background-color: #fab805;
  transition: 0.5s;
}

input:checked ~ .toggle-btn-circle {
  translate: -62px;
}

.event-category {
}

.event-category-input {
  height: 30px;
  margin-top: 10px;
  width: 50%;
}

.edit-file-upload {
  background-color: #fff;
  border: 1px solid #707070;
  display: flex;
  height: 30px;
  margin-top: 28px;
  width: 180px;
}

.edit-category-input {
  height: 30px;
  width: 50%;
}
</style>
