<template>
  <div class="event-wrapper">
    <Tag :query="'cat'" :now="Number($route.query.cat) || 1" />
    <ul class="event-content">
      <li
        v-for="(event, index) in list"
        v-bind:key="index"
        class="event-component"
        v-bind:class="{ 'event-component-reverse': index % 2 === 1 }"
      >
        <div class="event-text">
          <div class="event-title">
            <p class="event-subject">{{ event.title }}</p>
            <p class="event-date">{{ event.date }}</p>
          </div>
          <p class="event-description">
            {{ event.content }}
          </p>
        </div>
        <div class="event-img">
          <img :src="event.file_name" alt="sample.jpg" />
        </div>
      </li>
    </ul>
    <Pagination
      :query="'page'"
      :length="lastpage"
      :now="Number($route.query.page) || 1"
      :cat="Number($route.query.cat) || 1"
    />
  </div>
</template>
<script>
import Pagination from '../components/VuePagination.vue'
import Tag from '../components/VueTag.vue'

export default {
  name: 'EventContent',
  components: {
    Pagination,
    Tag,
  },
  data() {
    return {
      events: [],
      studies: [],
      perpage: 3,
      cat: 0,
      offset: 0,
      list: [],
      lastpage: 0,
    }
  },
  async created() {
    await this.getEvents()
    this.cat = this.$route.query.cat ? parseInt(this.$route.query.cat) : 1
    this.page = this.$route.query.page ? parseInt(this.$route.query.page) : 1
    this.setOffset()
    this.load()
    this.last()
  },
  methods: {
    async getEvents() {
      await this.$axios
        .get('/api/v1/events/category?cat=1')
        .then((response) => {
          this.events = response.data
        })
        .catch((err) => {
          console.log('err:', err)
        })
      await this.$axios
        .get('/api/v1/events/category?cat=2')
        .then((response) => {
          this.studies = response.data
        })
        .catch((err) => {
          console.log('err:', err)
        })
    },
    setOffset() {
      this.offset = this.page > 0 ? parseInt(this.page - 1) * this.perpage : 0 //何個目の要素から始まるか
    },
    load() {
      this.list = this.getItems(this.offset, this.perpage)
    },
    getItems(start, perpage) {
      var end = start + perpage
      if (this.cat == 1) {
        return this.events.slice(start, end)
      } else if (this.cat == 2) {
        return this.studies.slice(start, end)
      }
    },
    last() {
      if (this.cat == 1) {
        this.lastpage = Math.ceil(this.events.length / 3)
      } else if (this.cat == 2) {
        this.lastpage = Math.ceil(this.studies.length / 3)
      }
    },
  },
  beforeRouteUpdate(to, from) {
    const toCat = to.query.cat ? Number(to.query.cat) : 1
    const toPage = to.query.page ? Number(to.query.page) : 1
    const fromCat = from.query.cat ? Number(from.query.cat) : 1
    const fromPage = from.query.page ? Number(from.query.page) : 1
    if (toCat !== fromCat) {
      this.page = 1
      this.cat = to.query.cat
      this.setOffset()
      this.load()
      this.last()
    } else if (toPage !== fromPage) {
      this.page = to.query.page
      this.setOffset()
      this.load()
      this.last()
    }
  },
}
</script>

<style lang="scss" scoped>
.event {
  &-wrapper {
    background-color: #f8f8f8;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: auto;
  }
  &-component {
    background-color: #fff;
    display: flex;
  }
  &-text {
    display: flex;
    flex-direction: column;
  }
  &-title {
    align-items: end;
    border-bottom: 2px solid #dcdcdc;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
  }
  &-subject {
    align-items: center;
    display: flex;
    position: relative;
  }
  &-subject::before {
    color: #fd9603;
    content: '●';
    font-size: 12px;
    left: 0;
    position: absolute;
  }
  &-date {
    font-size: 12px;
  }
  &-description {
    line-height: 150%;
    white-space: pre-wrap;
  }
  &-img {
    display: flex;
    position: relative;
  }
  &-img::before {
    background-color: #fd9603;
    content: '';
    position: absolute;
    transform: rotate(-10deg);
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

@media screen and (min-width: 796px) {
  .event {
    &-wrapper {
      padding-top: 100px;
    }
    &-content {
      margin: 0;
      overflow: hidden;
      position: relative;
      width: 100%;
    }
    &-component {
      height: 450px;
      justify-content: space-between;
      left: 100px;
      padding: 50px;
      position: relative;
    }
    &-component + &-component {
      margin-top: 100px;
    }
    &-text {
      padding: 50px 0;
      width: 25%;
    }
    &-subject {
      font-size: 18px;
      padding-left: 30px;
    }
    &-description {
      padding-top: 40px;
    }
    &-img {
      top: -20px;
      width: 65%;
      img {
        object-fit: cover;
        transform: rotate(-5deg);
        width: 100%;
      }
    }
    &-img::before {
      height: 400px;
      left: -30px;
      top: 50px;
      width: 100%;
    }
    &-component-reverse {
      flex-direction: row-reverse;
      left: -100px;
      position: relative;
      .event-img img {
        transform: rotate(5deg);
      }
      .event-img::before {
        left: 30px;
        transform: rotate(10deg);
      }
    }
  }
}

@media screen and (max-width: 795px) {
  .event {
    &-content {
      padding: 0 7%;
      width: 100%;
    }
    &-component {
      align-items: center;
      flex-direction: column-reverse;
      margin: 0 auto;
      max-width: 600px;
    }
    &-component + &-component {
      margin-top: 60px;
    }
    &-text {
      padding: 25px;
      width: 80%;
    }
    &-subject {
      font-size: 14px;
      padding-left: 20px;
    }
    &-description {
      font-size: 14px;
      padding-top: 10px;
    }
    &-img {
      height: 230px;
      left: 10px;
      top: -15px;
      width: 100%;
      img {
        object-fit: cover;
        transform: rotate(-5deg);
        width: 100%;
      }
    }
    &-img::before {
      height: 200px;
      left: -10px;
      top: 40px;
      width: 90%;
    }
  }
}
</style>
