<template>
  <div class="category">
    <ul class="tab">
      <li
        v-for="category in categories"
        v-bind:key="category.id"
        class="name"
        v-bind:class="{ now: now === category.id }"
        @click="$router.push(`?${query}=${category.id}`)"
      >
        {{ category.category }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'EventTag',
  data() {
    return {
      categories: [
        {
          id: 1,
          category: 'イベント',
        },
        {
          id: 2,
          category: '勉強会',
        },
      ],
    }
  },
  props: {
    query: {
      type: String,
      required: true,
    },
    now: {
      type: Number,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

.category {
  margin: 0 auto;
}

.tab {
  align-items: center;
  display: flex;
  height: 250px;
  justify-content: center;
  list-style: none;
}

.now {
  border-bottom: 2px solid #fd9603;
}

.name {
  cursor: pointer;
}

@media screen and (min-width: 796px) {
  .name {
    font-size: 26px;
    padding: 10px;
  }
  .name + .name {
    margin-left: 150px;
  }
}

@media screen and (max-width: 795px) {
  .name {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0;
  }
  .name + .name {
    margin-left: 80px;
  }
  .now {
    color: #fd9603;
  }
}
</style>
