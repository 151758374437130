<template>
  <main class="main">
    <article class="main-inner">
      <h1 class="page-title">PRIVACY&nbsp;POLICY</h1>
      <span class="page-title-sub">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;プライバシーポリシー</span>
      <p class="page-text">
        株式会社iRupは（以下、「弊社」という。）は、弊社が提供するサービス（以下「本サービス」という。）における、ユーザーの個人情報を含む
        利用者情報の取り扱いについて、以下のとおりプライバシーポリシーを定めます。
      </p>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【1.収集する利用者情報】</h2>
        <p class="privacy-policy-text">
          プライバシーポリシーにおいて「個人情報」とは、氏名、電話番号、ユーザー情報を識別できる情報を指し、
          「利用者情報」とは、個人情報に加え、サービス利用上の行動の履歴、ユーザーとの電話連絡及びPC等の端末を通じた通信により発生、
          または蓄積された情報等であって、プライバシーポリシーに基づき弊社が収集するものを意味するとします。
          弊社は、本サービスにおいて、以下の利用者情報を適法かつ公正な手段で取得させていただきます。<br />
          <br />
          ・氏名<br />
          ・メールアドレス<br />
          ・電話番号<br />
          弊社が定める入力フォームにユーザーが入力する情報
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【2.個人情報の取得方法】</h2>
        <p class="privacy-policy-text">
          お問合せフォームやコメントの送信時には、氏名・電話番号・メールアドレスを取得させていただきます。
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【3.利用目的】</h2>
        <p class="privacy-policy-text">
          弊社が収集した利用者情報は、以下の目的により使用されます。<br />
          <br />
          お問い合わせに関する回答のため<br />
          弊社とユーザーの間で同意した目的のため<br />
          個人情報保護法によって許された目的のため
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【4.個人情報の管理】</h2>
        <p class="privacy-policy-text">
          個人情報へのアクセス、個人情報の漏えい、減失、又はき損の予防及び是正のため、当社内において規定を整備し安全対策に努めます。
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【5.第三者提供】</h2>
        <p class="privacy-policy-text">
          弊社は、利用者像法のうち、個人情報については、個人情報保護法その他の法令に基づき開示が認められる場合を除くほか、
          原則として、ユーザーの事前の同意を得ずに情報を第三者に提供はいたしません。ただし、以下の場合を除きます。<br />
          <br />
          弊社が利用目的の達成に必要な範囲内において個人情報の取り扱いの全部または一部を委託する場合<br />
          合併その他の自由による事業の承継に伴って個人情報が提供された場合<br />
          法令に基づき、裁判所の令状または警察等の公的機関の有効な命令により開示を求められた場合
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【6.個人情報の開示・訂正等】</h2>
        <p class="privacy-policy-text">
          弊社は、個人情報についての開示、内容の訂正・追加・削除及び利用の停止を求められた場合には適切にこれに対応します。
        </p>
      </section>
      <section class="privacy-policy-block">
        <h2 class="privacy-policy-title">【7.プライバシーポリシーの変更】</h2>
        <p class="privacy-policy-text">
          弊社は、法令で定めがある場合を除き、ユーザーの事前のご承諾を得ることなく、当サイト上に公示する事により、プライバシーポリシーを適宜変更できるものとします。
        </p>
      </section>
    </article>
  </main>
</template>
<script></script>
<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap');

.main {
  background-color: #f5f5f5;
  font-family: 'Noto Sans JP', 'Helvetica Neue', Helvetica, 'Hiragino Sans', 'Hiragino Kaku Gothic ProN', Arial,
    'Yu Gothic', Meiryo, sans-serif;
  line-height: 1.5;
  overflow: hidden;
  padding: 200px 100px 300px;
  &-inner {
    background-color: #fff;
    margin: 0 auto;
    max-width: 1000px;
    padding: 100px 80px 200px;
  }
}

.page {
  &-title {
    font-size: 40px;
    font-weight: 600;
    margin: 0;
    text-align: left;
    &-sub {
      display: block;
      font-size: 20px;
      letter-spacing: 0.1em;
      text-align: left;
      position: relative;
    }
    &-sub::before {
      content: '';
      position: absolute;
      background-color: #fd9602;
      display: inline-block;
      height: 5px;
      width: 40px;
      top: 14px;
    }
  }
  &-text {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 80px;
  }
}

.privacy-policy {
  &-block {
    margin-top: 100px;
  }
  &-title {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 0;
    margin-top: 0;
  }
  &-text {
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 0;
    margin-top: 0;
  }
}

@media screen and (max-width: 796px) {
  .main {
    padding: 122px 25px 120px;
    &-inner {
      padding: 25px 25px 50px;
    }
  }
  .page {
    &-title {
      font-size: 15px;
      &-sub {
        font-size: 10px;
        letter-spacing: 0.01em;
      }
      &-sub::before {
        top: 6px;
        height: 3px;
        width: 16px;
      }
    }
    &-text {
      font-size: 13px;
      margin-top: 50px;
    }
  }
  .privacy-policy {
    &-block {
      margin-top: 75px;
    }
    &-title {
      font-size: 15px;
    }
    &-text {
      font-size: 13px;
    }
  }
}
</style>
