<template>
  <div class="tag-line-bkimg">
    <img class="tag-line-bkimg-pic" :src="topImage" :alt="alt" />
  </div>
  <div id="tag">
    <div class="tag-line">
      <div class="tag-line-textarea">
        <div class="tag-line-textarea-text">
          <h1 class="tag-line-textarea-text-ttl">iRup社員</h1>
          <p class="tag-line-textarea-text-para">
            IT業界は横のつながりが大変希薄になりがちな業界です。<br />
            弊社では横のつながりはもちろん、縦のつながりも大切にしております。<br /><br />
            風通しのいい社風を通じて、社員と共に会社も成長していきます。
          </p>
        </div>
      </div>
    </div>
  </div>
  <div class="all-wrapper">
    <div class="contents" id="CEO" v-for="board in boards" :key="board">
      <div class="bk-color"></div>
      <div class="member">
        <div class="member-txtblock">
          <div class="member-txtblock-hd">
            <h2 class="member-txtblock-hd-ttl">{{ board.motto }}</h2>
            <div class="member-txtblock-hd-box">
              <p class="member-txtblock-hd-position">{{ board.position }}</p>
              <p class="member-txtblock-hd-name">
                {{ board.name }}
              </p>
            </div>
          </div>
          <div class="member--desc">
            <div class="member--desc-career">
              <div class="member--desc-career-ttl">
                <p>経歴</p>
              </div>
              <div class="member--desc-career-txt">
                <p class="member--desc-career-txt-para">
                  {{ board.history }}
                </p>
              </div>
            </div>
            <div class="member--desc-thought">
              <div class="member--desc-thought-ttl">
                <p>iRupへの想い</p>
              </div>
              <div class="member--desc-thought-txt">
                <p class="member--desc-thought-txt-para">
                  {{ board.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="member-imgblock">
          <img class="member-imgblock-pic" :src="board.file_name" alt="" />
        </div>
      </div>
    </div>
    <div class="contents" id="employee" v-for="employee in employees" :key="employee">
      <div class="bk-color"></div>
      <div class="member">
        <div class="member-txtblock">
          <div class="member-txtblock-hd">
            <h2 class="member-txtblock-hd-ttl">{{ employee.motto }}</h2>
            <div class="member-txtblock-hd-box">
              <p class="member-txtblock-hd-position">{{ employee.position }}</p>
              <p class="member-txtblock-hd-name">
                {{ employee.name }}
              </p>
            </div>
          </div>
          <div class="member--desc">
            <div class="member--desc-career">
              <div class="member--desc-career-ttl">
                <p>経歴</p>
              </div>
              <div class="member--desc-career-txt">
                <p class="member--desc-career-txt-para">
                  {{ employee.history }}
                </p>
              </div>
            </div>
            <div class="member--desc-thought">
              <div class="member--desc-thought-ttl">
                <p>iRupへの想い</p>
              </div>
              <div class="member--desc-thought-txt">
                <p class="member--desc-thought-txt-para">
                  {{ employee.content }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="member-imgblock">
          <img class="member-imgblock-pic" :src="employee.file_name" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MemberView',
  data() {
    return {
      topImage: require('@/assets/img/members/PC_member_1.jpg'),
      alt: '社員一覧',
      boards: [],
      employees: [],
    }
  },
  async created() {
    await this.$axios
      .get('/api/v1/members/limit?limit=3')
      .then((response) => {
        this.employees = response.data
      })
      .catch((err) => {
        console.log('err:', err)
      })
    await this.$axios
      .get('/api/v1/members/1')
      .then((response) => {
        this.boards = response.data
      })
      .catch((err) => {
        console.log('err:', err)
      })
    this.myMethod1()
  },
  methods: {
    myMethod1() {
      if (this.$route.hash === '#CEO') {
        this.scrollY = document.getElementById('CEO').scrollIntoView(true)
      } else if (this.$route.hash === '#employee') {
        this.scrollY = document.getElementById('employee').scrollIntoView(true)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// Google Fonts

@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);

// Reset CSS

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

// PC 796-px

.all-wrapper {
  font-family: 'Noto Sans Japanese', sans-serif;
  overflow: hidden;
}

.tag-line {
  font-family: 'Noto Sans Japanese', sans-serif;
  left: 0;
  margin-top: -7.4%;
  overflow: hidden;
  position: absolute;
  right: 0;
  &-bkimg {
    display: flex;
    margin-top: 82px;
    &-pic {
      object-fit: cover;
      width: 100vw;
      z-index: -1;
    }
  }
  &-textarea {
    background: transparent;
    &-text {
      background: #fff;
      margin: 0 auto;
      max-width: 1400px;
      width: 84.4%;
      &-ttl {
        color: #fd9603;
        font-size: 26px;
        letter-spacing: 1.82px;
        padding-top: 42px;
        text-align: center;
      }
      &-para {
        font-size: 18px;
        letter-spacing: 1.26px;
        line-height: 21px;
        margin: 0 auto;
        margin-top: 28px;
        padding-bottom: 65px;
        width: 60.3%;
      }
    }
  }
}

.contents {
  padding-bottom: 104px;
  padding-top: 280px;
  position: relative;
}

.bk-color {
  background: linear-gradient(180deg, #f5f5f5 0%, #f5f5f5 50%, #e6e6e6 50%, #e6e6e6 100%);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.member {
  display: flex;
  margin: 0 auto;
  max-width: 1400px;
  width: 84.4%;
  &-txtblock {
    margin-top: 50px;
    width: 50%;
    &-hd {
      &-ttl {
        font-size: 34px;
        font-weight: normal;
        letter-spacing: 2.38px;
      }
      &-position {
        color: #f0bb41;
        display: inline-block;
        font-size: 20px;
        letter-spacing: 1.4px;
        margin-top: 15px;
      }
      &-name {
        color: #f0bb41;
        display: inline-block;
        font-size: 20px;
        letter-spacing: 1.4px;
        margin-left: 25px;
        &-blank {
          margin-left: 15px;
        }
      }
    }
  }
  &--desc {
    margin-top: 87px;
    white-space: pre-wrap;
    &-career {
      &-ttl {
        color: #707070;
        display: flex;
        font-size: 16px;
        white-space: nowrap;
        &::after {
          background: #707070;
          content: '';
          height: 2px;
          margin: 10px 0 0 5px;
          width: 362px;
        }
      }
      &-txt {
        color: #707070;
        font-size: 16px;
        letter-spacing: 1.12px;
        line-height: 24px;
        margin-top: 21px;
      }
    }
    &-thought {
      margin-top: 65px;
      &-ttl {
        color: #707070;
        display: flex;
        font-size: 16px;
        white-space: nowrap;
        &::after {
          background: #707070;
          content: '';
          height: 2px;
          margin: 10px 0 0 5px;
          width: 292px;
        }
      }
      &-txt {
        color: #707070;
        font-size: 16px;
        letter-spacing: 1.12px;
        line-height: 24px;
        margin-top: 21px;
      }
    }
  }
  &-imgblock {
    width: 50%;
    &-pic {
      aspect-ratio: 4/5;
      min-width: 240px;
      object-fit: cover;
      padding-left: 17%;
      width: 63.1%;
    }
  }
}

// Partial Processing

.contents:first-of-type {
  padding-top: 341px;
  .member-txtblock-hd-position {
    color: #ee7b30;
  }
  .member-txtblock-hd-name {
    color: #ee7b30;
  }
}

.contents:nth-of-type(even) {
  .member-txtblock {
    order: 2;
  }
}

// SP Media Query -795px

@media screen and (max-width: 795px) {
  .all-wrapper {
    background: #f7f7f7;
  }
  .tag-line {
    &-textarea {
      &-text {
        min-width: 250px;
        width: 71.8%;
        &-ttl {
          font-size: 16px;
          letter-spacing: 1.05px;
          padding-top: 12px;
        }
        &-para {
          font-size: 10px;
          letter-spacing: 0.63px;
          line-height: 13px;
          margin-top: 6px;
          padding-bottom: 16px;
          width: 78.3%;
        }
      }
    }
    .sp-display {
      display: none;
    }
  }
  .contents {
    padding-bottom: 0;
    padding-top: 120px;
  }
  .bk-color {
    display: none;
  }
  .member {
    flex-wrap: wrap;
    padding-top: 39px;
    position: relative;
    width: 71.8%;
    &-txtblock {
      margin-top: 23.65px;
      order: 2;
      width: 100%;
      &-hd {
        &-ttl {
          font-size: 24px;
          letter-spacing: 1.12px;
        }
        &-box {
          left: 0;
          position: absolute;
          top: 0;
        }
        &-position {
          border: #ee7b30 solid 1px;
          color: #ee7b30;
          font-size: 14px;
          letter-spacing: 1.05px;
          margin-top: 0;
          padding: 5px;
        }
        &-name {
          color: black;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0;
          margin-left: 16px;
        }
      }
    }
    &--desc {
      margin-top: 27px;
      &-career {
        &-ttl {
          font-size: 14px;
          &::after {
            width: 100%;
          }
        }
        &-txt {
          font-size: 14px;
          margin-top: 26px;
          &-para {
            letter-spacing: 0.98px;
          }
        }
      }
      &-thought {
        margin-top: 47px;
        &-ttl {
          font-size: 14px;
          &::after {
            width: 100%;
          }
        }
        &-txt {
          font-size: 14px;
          margin-top: 26px;
          &-para {
            letter-spacing: 0.98px;
          }
        }
      }
    }
    &-imgblock {
      width: 100%;
      &-pic {
        aspect-ratio: 4/3;
        box-shadow: #53535329 15px 15px 20px;
        min-width: 100%;
        object-position: 54% 14%;
        padding-left: 0;
        width: 100%;
      }
    }
  }

  // Partial Processing
  .contents:first-of-type {
    padding-top: 200px;
    .member-txtblock-hd-name {
      color: black;
    }
  }
  .contents:nth-of-type(n + 2) {
    padding-bottom: 0;
    padding-top: 100px;
  }
  .contents:last-of-type {
    padding-bottom: 130px;
  }
}
</style>
